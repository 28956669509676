import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import ViewUtils from '../../ViewUtils';
import ModuleQualificationSetupSearchForm from '../program/ModuleQualificationSetupSearchForm';
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'QCTO Qualification ID' },
    { id: 'ProgrammeTitle', numeric: false, disablePadding: true, label: 'QCTO Qualification Title' },
    { id: 'StudentNumber', numeric: false, disablePadding: true, label: 'Student Number' },
    { id: 'CommencementDate', numeric: false, disablePadding: true, label: 'Commencement Date' },
    { id: 'CompletionDate', numeric: false, disablePadding: true, label: 'Completion Date' },
    { id: 'ContractNumber', numeric: false, disablePadding: true, label: 'Contract Number' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
    { id: 'CertificateNumber', numeric: false, disablePadding: true, label: 'Certificate Number' },
    { id: 'DateCertificateCreated', numeric: false, disablePadding: true, label: 'Date Certificate Created' }
];



const utils = new ViewUtils();

class LearnerQCTOQualificationAssessment extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = [
            { Name: "QCTOModuleQualificationID", Value: "" },
            { Name: "QCTOModuleQualificationTitle", Value: "" },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]

        let model =
        {
            QCTOModuleQualificationID: "",
            QCTOModuleQualificationTitle: "",
            LearnerID: this.props.location.state.learnerID

        }

        this.state = !utils.isNull(this.props.location.state.learnerID) ?
            {
                model: model,
                keyUpSwitch: false,
                toolbarConfig: {
                    items: [
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                            ]
                        }
                        ,
                        {
                            type: "devider"
                        },
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'edit', label: '', tooltip: 'View', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit }
                            ]
                        }
                    ]
                }
            }
            :
            {
                model: model,
                keyUpSwitch: false
            };

        //TODO: Add url for Candidacy removal
        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "edit") {
            console.log(this.state.selectedLearnerModuleQualification[0].Id);
            this.props.history.push({
                pathname: '/ViewQCTOQualificationAssessment',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    ModuleQualificationID: this.state.selectedLearnerModuleQualification[0].Id,
                    learnerNames: this.props.location.state.learnerNames
                }
            })
        }


    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnerModuleQualification: selection })
        } else {
            this.setState({ selectedLearnerModuleQualification: null })
        }
    }


    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (

            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > QCTO Part Qualification Assessments > ${this.props.location.state.learnerNames}`}
                </p>
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/LearnerQCTOQualificationAssessment/GetLearnerQCTOQualificationAssessments" : this.props.dataUrl}
                    entityViewPath='/ViewQCTOQualificationAssessment'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="ModuleQualificationProgrammeDescription" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <ModuleQualificationSetupSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LearnerQCTOQualificationAssessment));
