import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import ViewUtils from '../../ViewUtils';
import FormDialog from "../../etqa/FormDialog";
import { ViewContainer } from "../../View.jsx";
import { WSPandATRCreateForm } from "./WSPandATRCreateForm";
import { WSPandATREditForm } from "./WSPandATREditForm";
import EditDialog from './WspAtrEditDialog';
import withMenuPermission from "../../widgets/withMenuPermission";
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from "antd";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import WspAtrPrintReport from './WspAtrPrintReport';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year', widthRatio: '0.17' },
    { id: 'FormType', numeric: false, disablePadding: true, label: 'Form Type', widthRatio: '0.17' },
    { id: 'WSPStatus', numeric: false, disablePadding: true, label: 'WSP Status', widthRatio: '0.09' },
    { id: 'DueDate', numeric: false, disablePadding: true, label: 'Due Date', widthRatio: '0.09' },
    { id: 'SubmittedDate', numeric: false, disablePadding: true, label: 'Submitted Date', widthRatio: '0.1' },
    { id: 'SubmittedBy', numeric: false, disablePadding: true, label: 'Submitted By', widthRatio: '0.1' },
    { id: 'EvaluatedDate', numeric: false, disablePadding: true, label: 'Evaluated Date', widthRatio: '0.09' },
    { id: 'ApprovedDate', numeric: false, disablePadding: true, label: 'Approved Date', widthRatio: '0.1' },
    { id: 'RejectedDate', numeric: false, disablePadding: true, label: 'Rejected Date', widthRatio: '0.09' },

];


const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class WspAtrDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addDialogOpen: false,
            editDialogOpen: false,
            selectedWSPATR: null,
            selectedWSPATR: null,
            Message: "",
            MessageStyle: "",
            searchParameters: [{ Name: "OrganisationID", Value: this.props.location.state.organisationID }],
            OpenPrintDialog: false,
            clearSelectedRow: false,
            model: {
                FinancialYearID: { Id: 27, Description: "01 April 2025 – 31 March 2026" }
            }
        }


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Create WSP/ATR', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'view', label: '', tooltip: 'View WSP/ATR', icon: 'ViewIcon', disabled: true },
                        { id: "edit", label: "", tooltip: "Edit WSP/ATR", icon: "EditIcon", disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "print", label: "", tooltip: "Print WSP/ATR Report", disabled: true, icon: 'PrintIcon' },
                    ]
                }
            ]
        }
    }

    componentDidMount() {
        this.getDefaultFormType();
    }

    getDefaultFormType = () => {
        if (!utils.isNull(this.props.location.state.organisationID)) {
            let url = "api/sims/WSPATR/GetDefaultFormType?OrganisationID=" + this.props.location.state.organisationID;
            utils.invokeUrl(url, (response) => {
                ;
                this.setState({ model: { ...this.state.model, formTypeID: response } });
            },
                null,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/skillsOrganisation',
                state: { selection: this.props.location.state.organisationID }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ addDialogOpen: true, Message: "" })
        }
        else if ("edit" === buttonId) {
            this.setState({ editDialogOpen: true, Message: "" })
        }
        else if ("view" === buttonId) {
            this.props.history.push({
                pathname: "/WspAtrForms",
                state: {
                    WSPATRID: this.state.selectedWSPATR,
                    organisationID: this.props.location.state.organisationID
                }
            })
        } else if ("print" === buttonId) {
            this.setState({ OpenPrintDialog: true });
            /*this.FileDownload();*/
        }
    }

    DownloadAuthSmall = () => {
        utils.invokeUrl("api/sims/WSPATR/DownloadAuthorizationPageSmall", (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }
        );
    };

    DownloadAuthLarge = () => {
        utils.invokeUrl("api/sims/WSPATR/DownloadAuthorizationPageLarge", (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }
        );
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    postAddHandler = (cancel, save, response) =>  {
        if (save === true) {
            let message = "";
            if (this.IsJsonString(response.Message)) {
                let errResult = JSON.parse(response.Message);
                errResult.map((item, index) => (
                    message += item.Item1 + '\n'
                ))
            }
            else {
                message = response.Message;
            }
            
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                addDialogOpen: false,
                editDialogOpen: false,
                selectedWSPATR: null,
                Message: message,
                MessageStyle: response.MessageType,
                clearSelectedRow: true,
            });

            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("view", true);
            toolbarRef.current.setButtonDisabled("print", true);
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedWSPATR: null,
                Message: ""
            })
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("view", true);
            toolbarRef.current.setButtonDisabled("print", true);
        }
        else {
            this.setState({
                addDialogOpen: false,
                editDialogOpen: false,
                selectedWSPATR: null,
                Message: response.Message,
                MessageStyle: response.MessageType,
            })
            toolbarRef.current.setButtonDisabled("edit", true);
            toolbarRef.current.setButtonDisabled("view", true);
            toolbarRef.current.setButtonDisabled("print", true);
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedWSPATR: selection[0].Id, Message: "" });
        } else {
            this.setState({ selectedWSPATR: null });
        }
        toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("print", selection === null || selection.length === 0);
    }

    successfulContactRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedWSPATR: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulContactRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    ClosePrintDialog = () => {
        this.setState({ OpenPrintDialog: false });
        toolbarRef.current.setButtonDisabled("edit", true);
        toolbarRef.current.setButtonDisabled("view", true);
        toolbarRef.current.setButtonDisabled("print", true);
    };

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    IsJsonString(str) {
        try {
            var json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <ViewContainer title="">
                <p className="breadcrumbs">
                    {"WSP/ATR Dashboard" + (this.props.location.state.organisationName !== null ? " - " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />
                    <a
                        onClick={() => { this.DownloadAuthSmall() }}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{ textDecoration: "underline" }}
                    >Print Authorization Page Small</a>
                    <br />
                    <a
                        onClick={() => { this.DownloadAuthLarge() }}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{ textDecoration: "underline" }}
                    >Print Authorization Page Large</a>

                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/WSPATR/GetSearch`}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow} />
                </div>

                <FormDialog
                    open={this.state.addDialogOpen}
                    dialogTitle={"Create WSP/ATR"}
                    viewRef={this}
                    entityInfo={{ OrganisationID: this.props.location.state.organisationID }}
                    formRef={addFormRef}
                    saveUrl={"api/sims/wspatr/Addwspatr"}
                    usePost={true}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                >
                    <WSPandATRCreateForm ref={addFormRef} /*id={this.props.location.state.organisationID}*/ data={this.state.model} model={this.state.model} />
                </FormDialog>

                <EditDialog
                    open={this.state.editDialogOpen}
                    id={this.state.selectedWSPATR}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                />
                {
                    /*<FormDialog
                        open={this.state.editDialogOpen}
                        dialogTitle={"Edit WSP/ATR"}
                        viewRef={this}
                        formRef={addFormRef}
                        entityId={this.state.selectedWSPATR}
                        saveUrl={"api/sims/wspatr/Updatewspatr"}
                        usePut={true}
                        closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                    >
                        <WSPandATREditForm
                            ref={addFormRef}
                            id={this.state.selectedWSPATR}
                            dataURL='api/sims/wspatr/GetFind'
                        />
                    </FormDialog>*/
                }
                <Dialog fullScreen open={this.state.OpenPrintDialog} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.ClosePrintDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                Print WSP/ATR Report
                            </Typography>
                        </Toolbar >
                    </AppBar >
                    <WspAtrPrintReport WSPATRID={this.state.selectedWSPATR} classes={classes} />
                </Dialog>
            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(WspAtrDashboard)));