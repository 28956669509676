import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import LinearFlowHeading from '../../../widgets/LinearFlowHeading';
import AddProviderRoleSearch from './AddProviderRoleSearch';
import AddOnlineProviderDetails from './AddOnlineProviderDetails';
import OnlineProviderLearnership from './OnlineProviderLearnership';
import OnlineProviderQualification from './OnlineProviderQualification';
import OnlineProviderUnitStandard from './OnlineProviderUnitStandard';
import OnlineProviderSkillsProgramme from './OnlineProviderSkillsProgramme';
import AddOnlineProviderDocuments from './AddOnlineProviderDocuments';
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "../../../View.jsx";
import withMenuPermission from "../../../widgets/withMenuPermission";

const steps = [
    { name: "SDL Number", tooltip: "Capture Provider SDL Number" },
    { name: "Provider Details", tooltip: "Capture provider details" },
    { name: "Apply for Scope: Qualification", tooltip: "Capture qualification scope details" },
    { name: "Apply for Scope: Unit Standard", tooltip: "Capture unit standard scope details" },
    { name: "Apply for Scope: Skills Programme", tooltip: "Capture skills programme scope details" },
    { name: "Documents", tooltip: "Upload the required documents to be able to submit your application" }
];

const utils = new ViewUtils();
class AddOnlineProviderFlow extends Component {
    state = {
        currentStepIndex: 0,
        data: null,
        documents: {},
        saveMessage: "",
        key: Math.floor(100000000 + Math.random() * 900000000),
        hasSaveError: false,
        IsExternal: utils.isNull(this.props.IsExternal) ? false : this.props.IsExternal
    }

    navigate(index, forward) {
        if (forward) {
            if (index !== 5) {
                this.setState({ currentStepIndex: index + 1 });
            }
        } else {
            this.setState({ currentStepIndex: index - 1 });
        }
    }

    stepHandler(index, forward, data) {
        if (index === 0) {
            if (!utils.isNull(this.state.data) && !utils.isNull(this.state.data.providerRoleDetails) && !forward) {
                this.setState({
                    data: {  ...this.state.data, providerRoleDetails: { ...this.state.data.providerRoleDetails, Provider: data.ProviderDetails }
                    }
                }, () => { this.navigate(index, forward) });
            } else {
                this.setState({ data: data }, () => { this.navigate(index, forward) });
            }
        } else if (index === 1) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, providerRoleDetails: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 2) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, qualifications: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        }
        else if (index === 3) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, unitstandards: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        }
        else if (index === 4) {
            if (!utils.isNull(data)) {
                this.setState({
                    data: { ...this.state.data, skillsprogrammes: data }
                }, () => { this.navigate(index, forward) });
            } else if (!forward) {
                this.navigate(index, forward);
            }
        } else if (index === 5) {
            if (!utils.isNull(data) && forward) {
                let providerData = {};
                providerData.provider = this.state.data.providerRoleDetails;
                providerData.skillsprogrammes = this.state.data.skillsprogrammes.skillsprogrammes;
                providerData.qualifications = this.state.data.qualifications.qualifications;
                providerData.unitstandards = this.state.data.unitstandards.unitstandards;
                providerData.documents = data;
                providerData.GUID = this.state.key;
                providerData.RegistrationType = this.props.location.state.selectedRegistrationSecondaryType;

                utils.invokeUrlExternal("api/sims/OnlineProviderRegistration/AddOnlineProvider", (data) => {
                    this.props.history.push({
                        pathname: '/onlineRegistrationTypeSelect'
                    })
                }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(providerData)
                });

            } else {
                this.setState({
                    data: { ...this.state.data, documents: data }
                }, () => { this.navigate(index, forward) });
            }
        }
    }

    handleToolbarButtonClick(buttonId) {
    }

    componentDidMount() {
        utils.setLegend("Add Provider");
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <Row>
                    <Col>
                        <Row style={{ marginBottom: "20px" }}>
                            <Col> <LinearFlowHeading steps={steps} currentIndex={this.state.currentStepIndex} /></Col>
                        </Row>
                        {this.state.currentStepIndex === 0 ?

                            <AddProviderRoleSearch role="OnlineProviderRegistration" registrationType={this.props.location.state.selectedRegistrationSecondaryType}
                                data={this.state.data} IsExternal={this.props.IsExternal}
                                alreadyExistErrorMessage="An provider with the specified criteria already exist"
                                stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} />
                            : null}
                        {this.state.currentStepIndex === 1 ? <AddOnlineProviderDetails IsExternal={this.props.IsExternal} data={this.state.data} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 2 ? <OnlineProviderQualification regType={this.props.location.state.selectedRegistrationSecondaryType} data={this.state.data} IsExternal={this.props.IsExternal} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 3 ? <OnlineProviderUnitStandard regType={this.props.location.state.selectedRegistrationSecondaryType} data={this.state.data} IsExternal={this.props.IsExternal} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 4 ? <OnlineProviderSkillsProgramme regType={this.props.location.state.selectedRegistrationSecondaryType} data={this.state.data} IsExternal={this.props.IsExternal} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                        {this.state.currentStepIndex === 5 ? <AddOnlineProviderDocuments data={this.state.data} ukey={this.state.key} IsExternal={this.props.IsExternal} stepHandler={(index, forward, data) => this.stepHandler(index, forward, data)} /> : ""}
                    </Col>
                </Row>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AddOnlineProviderFlow));
