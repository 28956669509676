import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ModulesTable from '../ModulesTable';
import LinkDialog from "../LinkDialog";
import EnhancedToolbar from '../../widgets/Toolbar';
import ModuleSetupSearch from './ModuleSetupSearch';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();

const headCells = [
    { id: 'QCTOQualificationID', numeric: false, disablePadding: true, label: 'QCTO Module ID' },
    { id: 'QCTOQualificationTitle', numeric: false, disablePadding: true, label: 'QCTO Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'ModuleType', numeric: false, disablePadding: true, label: 'Module Type' }
];

const toolbarRef = React.createRef();
class QualificationModuleSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkDialogOpen: false,
            selectedLinkType: null,
            selectedLinkStandards: null,
            selectedModule: null,
            propmtOpen: false,
            promptTitle: "Unlink Module From Qualification",
            promptContent: "Are you sure you want to unlink this Module from the qualification?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "QCTOQualificationID", Value: props.location.state.qualificationId }],
            modTypeID: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            contextMenu: [
                                { id: 1, description: "Knowledge" },
                                { id: 2, description: "Practical Skill" },
                                { id: 3, description: "Work Experience" }
                            ],
                            id: 'link', label: 'Link', contextIcon: 'LinkIcon', tooltip: 'Link the selected Module to the qualification', width: "60px", visible: this.props.menuPermissionItem.Add
                        },
                        { id: 'unlink', label: '', icon: 'LinkOffIcon', tooltip: 'Un-link the selected Module from the qualification', visible: this.props.menuPermissionItem.Delete, disabled: utils.isNull(this.state.selectedModule) }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/Qualificationsetup',
                state: { selection: this.props.location.state.qualificationId, id: this.props.location.state.qualificationId }
            });
        } else if ("unlink" === buttonId) {
            this.setState({promptOpen: true})
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedModule: null,
                selectedLinkType: null,
                selectedLinkStandards: null,
                selectedModule: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unlinkModule(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedModule}&qualificationId=${this.props.location.state.qualificationId}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu, modTypeID: menu.id });
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedModule: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedModule: selection[0].Id,
                message: "",
                messageStyle: "",
            });
        } else {
            this.setState({
                selectedModule: null,
                message: "",
                messageStyle: "",
            });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false})
    }

    render() {
        return (
            <ViewContainer>
                <p className="legend">
                    {"Qualification > Modules"}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <ModulesTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/QualificationSetupModule/GetSearch"
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.qualificationTitle} 
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Modules to Qualification: ${this.props.location.state.qualificationTitle}`}
                    linkName={"Modules"} postUrl={"api/sims/QualificationSetupModule/linkModules"} usePost={true}
                    programmeId={this.props.location.state.qualificationId} programmeType="Q" 
                > 
                    <ModuleSetupSearch dataUrl="api/sims/QualificationSetupModule/GetSearchLinkableModules"
                        multiSelect={true} showTitle={false} moduletype={this.state.modTypeID}
                        programmeId={this.props.location.state.qualificationId} programmeType="Q"
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkModule(`api/sims/QualificationSetupModule/unlinkQualificationModule`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}
export default withRouter(withMenuPermission(QualificationModuleSearch));