import React, { useEffect, useState, Component } from 'react';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "../../View.jsx";
import EnhancedToolbar from '../../widgets/Toolbar';
import StakeholderfeedbackForm from './StackholderfeedbackForm';
import { withRouter } from 'react-router-dom';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();
const formRef = React.createRef();

class Feedback extends Component {

    constructor(props) {
        super(props);

    }

    state = {

        message: "",
        messageStyle: "",
        title: "",
        financialStakeholderFeedback: null,
        showTable: false,

        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,


        submitted: false,
        model: {
            FinancialYearObject: { Id: 27, Description: "01 April 2025 - 31 March 2026" }
        },
        Stakeholderfeedback: {
            ID: 0,
            OrganisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
            FinancialYearID: { Id: 27, Description: "01 April 2025 - 31 March 2026" },
            Question1YesNoID: 1,
            Question1YesComment: "",
            Question1NoComment: "",
            Question2YesNoID: 1,
            Question2YesComment: "",
            Question2NoComment: "",
            Question3YesNoID: 1,
            Question3YesComment: "",
            Question3NoComment: "",
            Question4YesNoID: 1,
            Question4YesComment: "",
            Question4NoComment: "",
            Question5YesNoID: 1,
            Question5YesComment: "",
            Question5NoComment: "",
            Question6YesNoID: 1,
            Question6YesComment: "",
            Question6NoComment: "",
            Question7YesNoID: 1,
            Question7YesComment: "",
            Question7NoComment: "",
            Question8YesNoID: 1,
            Question8YesComment: "",
            Question8NoComment: "",
        },
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        }

    }

    save() {
        if (((this.state.Stakeholderfeedback.Question1YesComment == "" || this.state.Stakeholderfeedback.Question1YesComment == undefined) && (this.state.Stakeholderfeedback.Question1NoComment == "" || this.state.Stakeholderfeedback.Question1NoComment == undefined)) ||
            ((this.state.Stakeholderfeedback.Question2YesComment == "" || this.state.Stakeholderfeedback.Question2YesComment == undefined) && (this.state.Stakeholderfeedback.Question2NoComment == "" || this.state.Stakeholderfeedback.Question2NoComment == undefined)) ||
            ((this.state.Stakeholderfeedback.Question3YesComment == "" || this.state.Stakeholderfeedback.Question3YesComment == undefined) && (this.state.Stakeholderfeedback.Question3NoComment == "" || this.state.Stakeholderfeedback.Question3NoComment == undefined)) ||
            ((this.state.Stakeholderfeedback.Question4YesComment == "" || this.state.Stakeholderfeedback.Question4YesComment == undefined) && (this.state.Stakeholderfeedback.Question4NoComment == "" || this.state.Stakeholderfeedback.Question4NoComment == undefined)) ||
            ((this.state.Stakeholderfeedback.Question5YesComment == "" || this.state.Stakeholderfeedback.Question5YesComment == undefined) && (this.state.Stakeholderfeedback.Question5NoComment == "" || this.state.Stakeholderfeedback.Question5NoComment == undefined)) ||
            ((this.state.Stakeholderfeedback.Question6YesComment == "" || this.state.Stakeholderfeedback.Question6YesComment == undefined) && (this.state.Stakeholderfeedback.Question6NoComment == "" || this.state.Stakeholderfeedback.Question6NoComment == undefined)) ||
            ((this.state.Stakeholderfeedback.Question7YesComment == "" || this.state.Stakeholderfeedback.Question7YesComment == undefined) && (this.state.Stakeholderfeedback.Question7NoComment == "" || this.state.Stakeholderfeedback.Question7NoComment == undefined)) ||
            ((this.state.Stakeholderfeedback.Question8YesComment == "" || this.state.Stakeholderfeedback.Question8YesComment == undefined) && (this.state.Stakeholderfeedback.Question8NoComment == "" || this.state.Stakeholderfeedback.Question8NoComment == undefined))) {
            this.state.submitted = true;
            this.forceUpdate();
        }

        else {
            let formValue = formRef.current.getValue();
            this.forceUpdate();
            this.state.submitted = true;
            if (formValue !== null && typeof formValue !== 'undefined') {
                let isAdd = true;
                formValue.OrganisationID = this.state.organisationID;
                formValue.FinancialYearID = formValue.FinancialYearObject;
                formValue.Question1YesNoID = this.state.Stakeholderfeedback.Question1YesNoID;
                formValue.Question1YesComment = this.state.Stakeholderfeedback.Question1YesComment;
                formValue.Question1NoComment = this.state.Stakeholderfeedback.Question1NoComment;

                formValue.Question2YesNoID = this.state.Stakeholderfeedback.Question2YesNoID;
                formValue.Question2YesComment = this.state.Stakeholderfeedback.Question2YesComment;
                formValue.Question2NoComment = this.state.Stakeholderfeedback.Question2NoComment;

                formValue.Question3YesNoID = this.state.Stakeholderfeedback.Question3YesNoID;
                formValue.Question3YesComment = this.state.Stakeholderfeedback.Question3YesComment;
                formValue.Question3NoComment = this.state.Stakeholderfeedback.Question3NoComment;

                formValue.Question4YesNoID = this.state.Stakeholderfeedback.Question4YesNoID;
                formValue.Question4YesComment = this.state.Stakeholderfeedback.Question4YesComment;
                formValue.Question4NoComment = this.state.Stakeholderfeedback.Question4NoComment;

                formValue.Question5YesNoID = this.state.Stakeholderfeedback.Question5YesNoID;
                formValue.Question5YesComment = this.state.Stakeholderfeedback.Question5YesComment;
                formValue.Question5NoComment = this.state.Stakeholderfeedback.Question5NoComment;

                formValue.Question6YesNoID = this.state.Stakeholderfeedback.Question6YesNoID;
                formValue.Question6YesComment = this.state.Stakeholderfeedback.Question6YesComment;
                formValue.Question6NoComment = this.state.Stakeholderfeedback.Question6NoComment;

                formValue.Question7YesNoID = this.state.Stakeholderfeedback.Question7YesNoID;
                formValue.Question7YesComment = this.state.Stakeholderfeedback.Question7YesComment;
                formValue.Question7NoComment = this.state.Stakeholderfeedback.Question7NoComment;

                formValue.Question8YesNoID = this.state.Stakeholderfeedback.Question8YesNoID;
                formValue.Question8YesComment = this.state.Stakeholderfeedback.Question8YesComment;
                formValue.Question8NoComment = this.state.Stakeholderfeedback.Question8NoComment;


                if (isAdd) {
                    const url = `api/sims/StakeholderFeedback/SaveUpdateStakeholderFeedback`;
                    utils.saveForm(this, formRef, url, (response) => {


                        this.state.submitted = false;
                        this.forceUpdate();

                        this.setState({ id: response.EntityId });
                        utils.showMessage(this, response.Message, response.MessageType);
                        this.successCallback(response);

                    }, this.error);
                }
            }
        }


    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    handleFormValueChange = (value, id) => {


        if (value != null && id != null) {
            //let StakeholderfeedbackUpdate = { ...this.state.Stakeholderfeedback };
            let StakeholderfeedbackUpdate = this.state.Stakeholderfeedback;

            if (id == "Question1YesNoID") {

                StakeholderfeedbackUpdate.Question1YesNoID = value;

                if (value == 2) {
                    StakeholderfeedbackUpdate.Question1NoComment = StakeholderfeedbackUpdate.Question1YesComment;
                    StakeholderfeedbackUpdate.Question1YesComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question1YesComment = StakeholderfeedbackUpdate.Question1NoComment;
                    StakeholderfeedbackUpdate.Question1NoComment = "";
                }

            }

            else if (id == "Question2YesNoID") {
                StakeholderfeedbackUpdate.Question2YesNoID = value;
                if (value == 2) {
                    StakeholderfeedbackUpdate.Question2NoComment = StakeholderfeedbackUpdate.Question2YesComment;
                    StakeholderfeedbackUpdate.Question2YesComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question2YesComment = StakeholderfeedbackUpdate.Question2NoComment;
                    StakeholderfeedbackUpdate.Question2NoComment = "";
                }
            }
            else if (id == "Question3YesNoID") {
                StakeholderfeedbackUpdate.Question3YesNoID = value;
                if (value == 2) {
                    StakeholderfeedbackUpdate.Question3NoComment = StakeholderfeedbackUpdate.Question3YesComment;
                    StakeholderfeedbackUpdate.Question3YesComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question3YesComment = StakeholderfeedbackUpdate.Question3NoComment;
                    StakeholderfeedbackUpdate.Question3NoComment = "";
                }
            }
            else if (id == "Question4YesNoID") {
                StakeholderfeedbackUpdate.Question4YesNoID = value;
                if (value == 2) {
                    StakeholderfeedbackUpdate.Question4NoComment = StakeholderfeedbackUpdate.Question4YesComment;
                    StakeholderfeedbackUpdate.Question4YesComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question4YesComment = StakeholderfeedbackUpdate.Question4NoComment;
                    StakeholderfeedbackUpdate.Question4NoComment = "";
                }
            }
            else if (id == "Question5YesNoID") {
                StakeholderfeedbackUpdate.Question5YesNoID = value;
                if (value == 2) {
                    StakeholderfeedbackUpdate.Question5NoComment = StakeholderfeedbackUpdate.Question5YesComment;
                    StakeholderfeedbackUpdate.Question5YesComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question5YesComment = StakeholderfeedbackUpdate.Question5NoComment;
                    StakeholderfeedbackUpdate.Question5NoComment = "";
                }
            }
            else if (id == "Question6YesNoID") {
                StakeholderfeedbackUpdate.Question6YesNoID = value;
                if (value == 2) {
                    StakeholderfeedbackUpdate.Question6NoComment = StakeholderfeedbackUpdate.Question6YesComment;
                    StakeholderfeedbackUpdate.Question6YesComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question6YesComment = StakeholderfeedbackUpdate.Question6NoComment;
                    StakeholderfeedbackUpdate.Question6NoComment = "";
                }
            }
            else if (id == "Question7YesNoID") {
                StakeholderfeedbackUpdate.Question7YesNoID = value;
                if (value == 2) {
                    StakeholderfeedbackUpdate.Question7NoComment = StakeholderfeedbackUpdate.Question7YesComment;
                    StakeholderfeedbackUpdate.Question7YesComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question7YesComment = StakeholderfeedbackUpdate.Question7NoComment;
                    StakeholderfeedbackUpdate.Question7NoComment = "";
                }
            }
            else if (id == "Question8YesNoID") {
                StakeholderfeedbackUpdate.Question8YesNoID = value;
                if (value == 2) {
                    StakeholderfeedbackUpdate.Question8NoComment = StakeholderfeedbackUpdate.Question8YesComment;
                    StakeholderfeedbackUpdate.Question8YesComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question8YesComment = StakeholderfeedbackUpdate.Question8NoComment;
                    StakeholderfeedbackUpdate.Question8NoComment = "";
                }
            }
            else if (id == "Question1") {
                if (StakeholderfeedbackUpdate.Question1YesNoID == 1) {
                    StakeholderfeedbackUpdate.Question1YesComment = value;
                    StakeholderfeedbackUpdate.Question1NoComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question1NoComment = value;
                    StakeholderfeedbackUpdate.Question1YesComment = "";
                }
            }
            else if (id == "Question2") {
                if (StakeholderfeedbackUpdate.Question2YesNoID == 1) {
                    StakeholderfeedbackUpdate.Question2YesComment = value;
                    StakeholderfeedbackUpdate.Question2NoComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question2NoComment = value;
                    StakeholderfeedbackUpdate.Question2YesComment = "";
                }
            }
            else if (id == "Question3") {
                if (StakeholderfeedbackUpdate.Question3YesNoID == 1) {
                    StakeholderfeedbackUpdate.Question3YesComment = value;
                    StakeholderfeedbackUpdate.Question3NoComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question3NoComment = value;
                    StakeholderfeedbackUpdate.Question3YesComment = "";
                }
            }
            else if (id == "Question4") {
                if (StakeholderfeedbackUpdate.Question4YesNoID == 1) {
                    StakeholderfeedbackUpdate.Question4YesComment = value;
                    StakeholderfeedbackUpdate.Question4NoComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question4NoComment = value;
                    StakeholderfeedbackUpdate.Question4YesComment = "";
                }
            }
            else if (id == "Question5") {
                if (StakeholderfeedbackUpdate.Question5YesNoID == 1) {
                    StakeholderfeedbackUpdate.Question5YesComment = value;
                    StakeholderfeedbackUpdate.Question5NoComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question5NoComment = value;
                    StakeholderfeedbackUpdate.Question5YesComment = "";
                }
            }
            else if (id == "Question6") {
                if (StakeholderfeedbackUpdate.Question6YesNoID == 1) {
                    StakeholderfeedbackUpdate.Question6YesComment = value;
                    StakeholderfeedbackUpdate.Question6NoComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question6NoComment = value;
                    StakeholderfeedbackUpdate.Question6YesComment = "";
                }
            }



            else if (id == "Question7") {
                if (StakeholderfeedbackUpdate.Question7YesNoID == 1) {
                    StakeholderfeedbackUpdate.Question7YesComment = value;
                    StakeholderfeedbackUpdate.Question7NoComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question7NoComment = value;
                    StakeholderfeedbackUpdate.Question7YesComment = "";
                }
            }
            else if (id == "Question8") {
                if (StakeholderfeedbackUpdate.Question8YesNoID == 1) {
                    StakeholderfeedbackUpdate.Question8YesComment = value;
                    StakeholderfeedbackUpdate.Question8NoComment = "";
                }

                else {
                    StakeholderfeedbackUpdate.Question8NoComment = value;
                    StakeholderfeedbackUpdate.Question8YesComment = "";
                }
            }


            else if (id == "financialId") {
                StakeholderfeedbackUpdate.FinancialYearID = value.ID;
            }
            this.setState({
                Stakeholderfeedback: {
                    ID: StakeholderfeedbackUpdate.ID,
                    OrganisationID: StakeholderfeedbackUpdate.OrganisationID,
                    FinancialYearID: StakeholderfeedbackUpdate.FinancialYearID,
                    Question1YesNoID: StakeholderfeedbackUpdate.Question1YesNoID,
                    Question1YesComment: StakeholderfeedbackUpdate.Question1YesComment,
                    Question1NoComment: StakeholderfeedbackUpdate.Question1NoComment,
                    Question2YesNoID: StakeholderfeedbackUpdate.Question2YesNoID,
                    Question2YesComment: StakeholderfeedbackUpdate.Question2YesComment,
                    Question2NoComment: StakeholderfeedbackUpdate.Question2NoComment,
                    Question3YesNoID: StakeholderfeedbackUpdate.Question3YesNoID,
                    Question3YesComment: StakeholderfeedbackUpdate.Question3YesComment,
                    Question3NoComment: StakeholderfeedbackUpdate.Question3NoComment,
                    Question4YesNoID: StakeholderfeedbackUpdate.Question4YesNoID,
                    Question4YesComment: StakeholderfeedbackUpdate.Question4YesComment,
                    Question4NoComment: StakeholderfeedbackUpdate.Question4NoComment,
                    Question5YesNoID: StakeholderfeedbackUpdate.Question5YesNoID,
                    Question5YesComment: StakeholderfeedbackUpdate.Question5YesComment,
                    Question5NoComment: StakeholderfeedbackUpdate.Question5NoComment,
                    Question6YesNoID: StakeholderfeedbackUpdate.Question6YesNoID,
                    Question6YesComment: StakeholderfeedbackUpdate.Question6YesComment,
                    Question6NoComment: StakeholderfeedbackUpdate.Question6NoComment,
                    Question7YesNoID: StakeholderfeedbackUpdate.Question7YesNoID,
                    Question7YesComment: StakeholderfeedbackUpdate.Question7YesComment,
                    Question7NoComment: StakeholderfeedbackUpdate.Question7NoComment,
                    Question8YesNoID: StakeholderfeedbackUpdate.Question8YesNoID,
                    Question8YesComment: StakeholderfeedbackUpdate.Question8YesComment,
                    Question8NoComment: StakeholderfeedbackUpdate.Question8NoComment,
                }

            });

            this.forceUpdate();



        }

        if (id == "" || id == undefined) {
            if (formRef) {
                if (formRef.current) {
                    var i = formRef.current.getValue();
                    if (true) {
                        if (i != null && i != undefined) {
                            this.setState({ showTable: true });
                            const url = "api/sims/StakeholderFeedback/FindStakeholderFeedbackDetailsById" + "?organisationId=" + this.state.Stakeholderfeedback.OrganisationID + "&financialYearId=" + i.FinancialYearObject.Id;
                            utils.invokeUrl(url, (response) => {
                                this.dataArrivedHandler(response);
                                if (response.Question1YesNoID != 0 || response.Question2YesNoID != 0) {


                                    this.state.Stakeholderfeedback.Question1YesNoID = response.Question1YesNoID;
                                    if (response.Question1YesNoID == 1) {
                                        document.getElementById("1").value = response.Question1YesComment;
                                        this.state.Stakeholderfeedback.Question1YesComment = response.Question1YesComment;
                                    }

                                    else {
                                        document.getElementById("1").value = response.Question1NoComment;
                                        this.state.Stakeholderfeedback.Question1NoComment = response.Question1NoComment;
                                    }


                                    this.state.Stakeholderfeedback.Question2YesNoID = response.Question2YesNoID;
                                    if (response.Question2YesNoID == 1) {
                                        document.getElementById("2").value = response.Question2YesComment;
                                        this.state.Stakeholderfeedback.Question2YesComment = response.Question2YesComment;
                                    }

                                    else {
                                        document.getElementById("2").value = response.Question2NoComment;
                                        this.state.Stakeholderfeedback.Question2NoComment = response.Question2NoComment;
                                    }


                                    this.state.Stakeholderfeedback.Question3YesNoID = response.Question3YesNoID;
                                    if (response.Question3YesNoID == 1) {
                                        document.getElementById("3").value = response.Question3YesComment;
                                        this.state.Stakeholderfeedback.Question3YesComment = response.Question3YesComment;
                                    }

                                    else {
                                        document.getElementById("3").value = response.Question3NoComment;
                                        this.state.Stakeholderfeedback.Question3NoComment = response.Question3NoComment;
                                    }


                                    this.state.Stakeholderfeedback.Question4YesNoID = response.Question4YesNoID;
                                    if (response.Question4YesNoID == 1) {
                                        document.getElementById("4").value = response.Question4YesComment;
                                        this.state.Stakeholderfeedback.Question4YesComment = response.Question4YesComment;
                                    }

                                    else {
                                        document.getElementById("4").value = response.Question4NoComment;
                                        this.state.Stakeholderfeedback.Question4NoComment = response.Question4NoComment;
                                    }


                                    this.state.Stakeholderfeedback.Question5YesNoID = response.Question5YesNoID;
                                    if (response.Question5YesNoID == 1) {
                                        document.getElementById("5").value = response.Question5YesComment;
                                        this.state.Stakeholderfeedback.Question5YesComment = response.Question5YesComment;
                                    }

                                    else {
                                        document.getElementById("5").value = response.Question5NoComment;
                                        this.state.Stakeholderfeedback.Question5NoComment = response.Question5NoComment;
                                    }


                                    this.state.Stakeholderfeedback.Question6YesNoID = response.Question6YesNoID;
                                    if (response.Question6YesNoID == 1) {
                                        document.getElementById("6").value = response.Question6YesComment;
                                        this.state.Stakeholderfeedback.Question6YesComment = response.Question6YesComment;
                                    }

                                    else {
                                        document.getElementById("6").value = response.Question6NoComment;
                                        this.state.Stakeholderfeedback.Question6NoComment = response.Question6NoComment;
                                    }


                                    this.state.Stakeholderfeedback.Question7YesNoID = response.Question7YesNoID;
                                    if (response.Question7YesNoID == 1) {
                                        document.getElementById("7").value = response.Question7YesComment;
                                        this.state.Stakeholderfeedback.Question7YesComment = response.Question7YesComment;
                                    }

                                    else {
                                        document.getElementById("7").value = response.Question7NoComment;
                                        this.state.Stakeholderfeedback.Question7NoComment = response.Question7NoComment;
                                    }

                                    this.state.Stakeholderfeedback.Question8YesNoID = response.Question8YesNoID;
                                    if (response.Question8YesNoID == 1) {
                                        document.getElementById("8").value = response.Question8YesComment;
                                        this.state.Stakeholderfeedback.Question8YesComment = response.Question8YesComment;
                                    }

                                    else {
                                        document.getElementById("8").value = response.Question8NoComment;
                                        this.state.Stakeholderfeedback.Question8NoComment = response.Question8NoComment;
                                    }

                                    this.setState({
                                        Stakeholderfeedback: {
                                            OrganisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
                                            FinancialYearID: {},
                                            Question1YesNoID: response.Question1YesNoID,
                                            Question1YesComment: response.Question1YesComment,
                                            Question1NoComment: response.Question1NoComment,
                                            Question2YesNoID: response.Question2YesNoID,
                                            Question2YesComment: response.Question2YesComment,
                                            Question2NoComment: response.Question2NoComment,
                                            Question3YesNoID: response.Question3YesNoID,
                                            Question3YesComment: response.Question3YesComment,
                                            Question3NoComment: response.Question3NoComment,
                                            Question4YesNoID: response.Question4YesNoID,
                                            Question4YesComment: response.Question4YesComment,
                                            Question4NoComment: response.Question4NoComment,
                                            Question5YesNoID: response.Question5YesNoID,
                                            Question5YesComment: response.Question5YesComment,
                                            Question5NoComment: response.Question5NoComment,
                                            Question6YesNoID: response.Question6YesNoID,
                                            Question6YesComment: response.Question6YesComment,
                                            Question6NoComment: response.Question6NoComment,
                                            Question7YesNoID: response.Question7YesNoID,
                                            Question7YesComment: response.Question7YesComment,
                                            Question7NoComment: response.Question7NoComment,
                                            Question8YesNoID: response.Question8YesNoID,
                                            Question8YesComment: response.Question8YesComment,
                                            Question8NoComment: response.Question8NoComment,
                                        }

                                    });


                                    this.forceUpdate();
                                }
                                else {
                                    document.getElementById("1").value = "";

                                    document.getElementById("2").value = "";

                                    document.getElementById("3").value = "";

                                    document.getElementById("4").value = "";

                                    document.getElementById("5").value = "";

                                    document.getElementById("6").value = "";

                                    document.getElementById("7").value = "";

                                    document.getElementById("8").value = "";


                                    this.state.Stakeholderfeedback.Question1YesNoID = 1;
                                    this.state.Stakeholderfeedback.Question2YesNoID = 1;
                                    this.state.Stakeholderfeedback.Question3YesNoID = 1;
                                    this.state.Stakeholderfeedback.Question4YesNoID = 1;
                                    this.state.Stakeholderfeedback.Question5YesNoID = 1;
                                    this.state.Stakeholderfeedback.Question6YesNoID = 1;
                                    this.state.Stakeholderfeedback.Question7YesNoID = 1;
                                    this.state.Stakeholderfeedback.Question8YesNoID = 1;


                                    this.setState({
                                        Stakeholderfeedback: {
                                            OrganisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
                                            FinancialYearID: {},
                                            Question1YesNoID: 1,
                                            Question1YesComment: "",
                                            Question1NoComment: "",
                                            Question2YesNoID: 1,
                                            Question2YesComment: "",
                                            Question2NoComment: "",
                                            Question3YesNoID: 1,
                                            Question3YesComment: "",
                                            Question3NoComment: "",
                                            Question4YesNoID: 1,
                                            Question4YesComment: "",
                                            Question4NoComment: "",
                                            Question5YesNoID: 1,
                                            Question5YesComment: "",
                                            Question5NoComment: "",
                                            Question6YesNoID: 1,
                                            Question6YesComment: "",
                                            Question6NoComment: "",
                                            Question7YesNoID: 1,
                                            Question7YesComment: "",
                                            Question7NoComment: "",
                                            Question8YesNoID: 1,
                                            Question8YesComment: "",
                                            Question8NoComment: "",
                                        }

                                    });

                                    this.forceUpdate();
                                }


                            }, {});
                        }
                    }

                }
            }
            this.state.submitted = false;
        }

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("save" === buttonId) {
            this.save();
        } else {
            this.close();
        }
    }

    updateTheState() {
        this.forceUpdate();
    }


    close() {
        this.props.history.push({
            pathname: '/skillsOrganisation',
            state: { selection: this.props.location.state.organisationID }
        });
    }

    dataArrivedHandler = (values) => {
        console.log(values);
        if (!utils.isNull(values["ID"]) && values["ID"] !== 0) {
            this.setState({ id: values["ID"] }, () => {
                this.SetToolbarConfig();
            });
        }
        else {
            this.SetToolbarConfig();
        }
    }

    SetToolbarConfig = () => {
        this.setState({
            toolbarConfig: {
                items: [
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                        ]
                    }
                    ,
                    {
                        type: "devider"
                    },
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: !utils.isNull(this.state.id) ? this.props.menuPermissionItem.Edit : this.props.menuPermissionItem.Add }
                        ]
                    }
                ]
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Organisation > Stakeholder Feedback " + (this.props.location.state.organisationName !== null ? "> " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Organisation" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <StakeholderfeedbackForm ref={formRef} state={this.state} data={this.state.model} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null} valueChangeHandler={(values, event) => this.handleFormValueChange(values, event)} dataArrivedHandler={(values) => this.dataArrivedHandler(values)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>

        );
    }
}
export default withRouter(withMenuPermission(Feedback));