import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "../../../View.jsx";
import AlertItem from "../../../widgets/AlertItem";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../../widgets/Toolbar';
import LinkDialog from "../../LinkDialog";
import Prompt from '../../../widgets/Prompt';
import SkillsProgramSetupSearch from '../../program/SkillsProgramSetupSearch';

export const SkillsProgrammeHeadCells = [
    { id: 'SkillsProgrammeCode', numeric: false, disablePadding: true, label: 'Skills Programme Code' },
    { id: 'SkillsProgrammeTitle', numeric: false, disablePadding: true, label: 'Skills Programme Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' }
];

const linkToolbarRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to unit standard scope', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to documents', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class OnlineAssessorSkillsProgramme extends Component {
    constructor(props) {
        super(props)
        let data = {};

        this.state = {
            model: !utils.isNull(props.data) && !utils.isNull(props.data.skillsprogrammes) ? props.data.skillsprogrammes : { skillsprogrammes: [] },
            showTable: true,
            SkillsProgrammeIds: SkillsProgrammeIds,
            linkDialogOpen: false,
            formDialogOpen: false,
            clearSelectedRow: false,
            selectedSkillsProgramme: null,
            propmtOpen: false,
            promptTitle: "Unlink SkillsProgramme From Assessor",
            promptContent: "Are you sure you want to unlink this skills programme from the assessor?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "AssessorID", Value: this.props.location.state.AssessorID }]
        }

        this.linkToolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link skills programme/s to the assessor', icon: 'LinkIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link selected skills programme from the assessor', disabled: utils.isNull(this.state.selectedSkillsProgramme), icon: 'LinkOffIcon' }
                    ]
                }
            ]
        };

        let SkillsProgrammeIds = "";

        if (!utils.isNull(props.data) && !utils.isNull(props.data.skillsprogrammes)) {
            this.setState({ SkillsProgrammeIds: this.getIdList(props.data.skillsprogrammes) });
        }
        else {
            if (this.props.regType == "reregistration") {
                this.AutoGrabAssessorSkillsProgrammeScope();
            }
        }

    }

    AutoGrabAssessorSkillsProgrammeScope() {
        let url = "api/sims/OnlineAssessorRegistration/GetPrefillAssessorSkillsProgrammeScope?PersonID=" + this.props.data.personRoleDetails.ID;
        utils.invokeUrlExternal(url, (response) => {
            this.setState({
                model: {
                    ...this.state.model,
                    ["skillsprogrammes"]: response[0].Data
                }
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    saveSkillsProgrammes(selection) {
        for (let i = 0; i < selection.length; i++) {
            this.state.model["skillsprogrammes"].push(selection[i]);
        }
        this.setState({ SkillsProgrammeIds: this.getIdList(selection) });
    }

    getIdList(programmes) {
        let exclusions = "";

        for (let i = 0; i < programmes.length; i++) {
            exclusions += programmes[i].Id;
            if (i < programmes.length - 1) {
                exclusions += ","
            }
        }

        return exclusions;
    }

    handleToolbarButtonClick(buttonId) {
        if ("back" === buttonId) {
            this.props.stepHandler(4, false, this.state.model);
        } else if ("next" === buttonId) {
            if (!utils.isNull(this.state.model)) {
                this.props.stepHandler(4, true, this.state.model);
            }
        }
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedSkillsProgramme: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    unlinkSkillsProgramme() {
        this.setState({ promptOpen: false }, () => {
            linkToolbarRef.current.setButtonDisabled("unlink", true);
        })

        let programmes = this.state.model.skillsprogrammes;
        let newProgrammes = [];

        for (let i = 0; i < programmes.length; i++) {
            if (programmes[i].Id !== this.state.selectedProgrammeId) {
                newProgrammes.push(programmes[i]);
            }
        }
        let newIds = this.getIdList(newProgrammes);
        this.setState({ SkillsProgrammeIds: newIds, selectedProgrammeId: null, clearSelectedRow: true });
        this.setState({ model: { ...this.state.model, ["skillsprogrammes"]: newProgrammes } });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedProgrammeId: selection[0].Id, clearSelectedRow: false });
        } else {
            this.setState({ selectedProgrammeId: null, clearSelectedRow: false });
        }
        linkToolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false, clearSelectedRow: true })
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    handleTopToolbarButtonClick = (buttonId) => {
        if  ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    render() {
            return (
                <ViewContainer title="">
                    <p>
                        Online Assessor Registration: SkillsProgramme Scope
                    </p>
                    <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                    <div className="searchResults">
                        <EnhancedToolbar
                            highlight={false}
                            ref={linkToolbarRef}
                            buttonClickHandler={(buttonId) => this.handleTopToolbarButtonClick(buttonId)}
                            config={this.linkToolbarConfig} />

                        {this.state.showTable === true && (
                            <EnhancedTable
                                headCells={SkillsProgrammeHeadCells}
                                rows={this.state.model.skillsprogrammes}
                                autoFetchData={false}
                                height="50vh"
                                clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                                onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                                searchParameters={this.state.searchParameters} paged={true} />
                        )}
                    </div>

                    <LinkDialog open={this.state.linkDialogOpen} linkHandler={(selection) => { this.saveSkillsProgrammes(selection) }}
                        closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                        dialogTitle={`Link Skills Programme to Assessor`}
                    >
                        <SkillsProgramSetupSearch dataUrl="api/sims/OnlineAssessorRegistration/GetSearchLinkableSkillsProgrammes"
                            multiSelect={true} showTitle={false} selectionCallbackMode="full"
                            IsExternal={this.props.IsExternal}
                            programmeType="Q" exclusions={this.state.SkillsProgrammeIds}
                        />
                    </LinkDialog>

                    <Prompt open={this.state.promptOpen}
                        promptTitle={this.state.promptTitle}
                        promptContent={this.state.promptContent}
                        handleYes={() => this.unlinkSkillsProgramme()}
                        closePrompt={this.closePrompt}
                    />
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="90%">
                                </td>
                                <td width="20%" align="right">
                                    <div style={{ width: "120px", marginTop: "16px" }}>
                                        <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ViewContainer>
            );
    }
}

export default withRouter(
    connect()(OnlineAssessorSkillsProgramme)
);
