import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import WSPATRApprovalSearchForm from './WSPATRApprovalSearchForm';
import WSPATRApprovalBulkForm from './WSPATRApprovalBulkForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import BulkDialog from './WSPATRApprovalBulkDialog';
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const formRef = React.createRef();

class WSPATRApprovalSearch extends Component {

    constructor(props) {
        super(props);


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon', disabled: true },
                        { id: 'bulk', label: 'Bulk Approve / Reject', tooltip: '', disabled: true, visible: this.props.menuPermissionItem.Add },
                    ]
                }
            ]
        }

        this.searchParameters =
            [
                { Name: "SDLNumber", Value: "" },
                { Name: "FinancialYearID", Value: "" },

            ]

        this.headCells =
            [
                { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
                { id: 'CompanyName', numeric: false, disablePadding: true, label: 'Company Name' },
                { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
                { id: 'VerificationDate', numeric: false, disablePadding: true, label: 'Verification Date' },
                { id: 'VerifiedBy', numeric: false, disablePadding: true, label: 'Verification By' },
                { id: 'VerificationStatus', numeric: false, disablePadding: true, label: 'Verification Status' },
                { id: 'DateEvaluated', numeric: false, disablePadding: true, label: 'Date Evaluated' },
                { id: 'EvaluatedBy', numeric: false, disablePadding: true, label: 'Evaluated By' },
                { id: 'EvaluationStatus', numeric: false, disablePadding: true, label: 'Evaluation Status' },
                { id: 'ApprovalStatus', numeric: false, disablePadding: true, label: 'Approval Status' },

            ]

        let model =
        {
            SDLNumber: "",
            FinancialYearID: { Id: 27, Description: "01 April 2025 - 31 March 2026" }
        }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedWSPATR: null,
            selectedWSPATRs: null,
            showBulk: false,
            clearGrid: false,
            searchParameters: [{ Name: "SDLNumber", Value: "" }, { Name: "FinancialYearID", Value: "" } ]
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        
        if (selection.length > 1) {
            this.setState({ selectedWSPATR: null, selectedWSPATRs: selection });
            toolbarRef.current.setButtonDisabled("bulk", false);
            toolbarRef.current.setButtonDisabled("view", true);
        }
        else if (selection.length === 1) {
            this.setState({ selectedWSPATR: selection[0].Id, selectedWSPATRs: null });
            toolbarRef.current.setButtonDisabled("bulk", true);
            toolbarRef.current.setButtonDisabled("view", false);
        }
        else {
            this.setState({ selectedWSPATR: null, selectedWSPATRs: null });
            toolbarRef.current.setButtonDisabled("bulk", true);
            toolbarRef.current.setButtonDisabled("view", true);
        }

    }

    handleToolbarButtonClick = (buttonId) => {
        
        if ("bulk" === buttonId) {
            if (this.state.selectedWSPATRs !== null) { this.setState({ showBulk: true }); }
        }
        else if ("view" === buttonId) {
            this.props.history.push({
                pathname: "/WSPATRApprovalMain",
                state: {
                    WSPATRID: this.state.selectedWSPATR
                }
            })
        }        
    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                let message = "";
            if (this.IsJsonString(response.Message)) {
                let errResult = JSON.parse(response.Message);

                errResult.map((item, index) => (
                    message += item.Item1 + '\n'
                ))
            }
            else {
                message = response.Message;
            }
                this.setState({
                    searchParameters: this.state.searchParameters,
                    showBulk: false,
                    clearGrid: true,
                    selectedWSPATR: null,
                    selectedWSPATRs: null,
                    Message: message,
                    MessageStyle: "success",
                    clearSelectedRow: true
                })
            toolbarRef.current.setButtonDisabled("view", true);
            toolbarRef.current.setButtonDisabled("bulk", true);
            }
            else if (response.MessageType === "ERROR") {
                let message = "";
                if (this.IsJsonString(response.Message)) {
                    let errResult = JSON.parse(response.Message);
    
                    errResult.map((item, index) => (
                        message += item.Item1 + '\n'
                    ))
                }
                else {
                    message = response.Message;
                }
                    this.setState({
                        searchParameters: this.state.searchParameters,
                        showBulk: false,
                        clearGrid: true,
                        selectedWSPATR: null,
                        selectedWSPATRs: null,
                        Message: message,
                        MessageStyle: "warning"
                    })
            }
        }
        else if (cancel === true) {
            this.setState({
                showBulk: false,
                selectedWSPATR: null,
                selectedWSPATRs: null,
                clearSelectedRow: true
            })
            toolbarRef.current.setButtonDisabled("view", true);
            toolbarRef.current.setButtonDisabled("bulk", true);
        }
    }

    IsJsonString(str) {
        try {
            var json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }

    onGridCleared = () => {
        this.setState({ clearGrid: false });
    }



    render() {
        return (

            <ViewContainer title={""}>
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />
                <br/>
                    <SearchView headCells={this.headCells}
                        dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/WSPATRApproval/getsearch"}
                        model={this.state.model}
                        title='WSP/ATR Approval'
                        toolbarConfig={this.toolbarConfig}
                        searchParameters={this.state.searchParameters}
                        buttonClickHandler={this.handleToolbarButtonClick}
                        multiSelect={true}
                        onLookupValueChange={this.props.onLookupValueChange}
                        descriptionProp="LegalName"
                        selectionCallbackMode={this.props.selectionCallbackMode}
                        history={this.props.history}
                        keyUpSwitch={this.state.keyUpSwitch}
                        setSelection={(selection) => { this.handleSelection(selection) }}
                        toolbarRef={toolbarRef}
                        clearGrid={this.state.clearGrid}
                        onGridCleared={this.onGridCleared}
                    >
                        <WSPATRApprovalSearchForm
                            data={this.state.model}
                            onKeyUp={(e) => { this.onkeyup(e) }}
                            valueChangeHandler={(values) => {
                                if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                    this.setState({ model: values });
                                }
                            }}
                        />
                    </SearchView>
                <BulkDialog
                    open={this.state.showBulk}
                    selectedWSPATRs={this.state.selectedWSPATRs}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                />
            </ViewContainer>
        );
    }
}
export default withRouter(withMenuPermission(WSPATRApprovalSearch));