import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import "./Assessor.css";
import { AssessorForm } from './AssessorForm';
import { ViewContainer } from "../../../View";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import withMenuPermission from "../../../widgets/withMenuPermission";


const utils = new ViewUtils();


const assessorRef = React.createRef();

const lowertoolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to assessor approval search', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to learnership scope', theme: true }
            ]
        }
    ]
};

class AssessorApproval extends Component {

    state = {
        AssessorDetails: {
            Person: {
                FirstName: "",
                Surname: "",
                IDNo: ""
            }
        },
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        
                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ AssessorDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close();
        }
    }

    handleLowerToolbarButtonClick(buttonId) {
        if ("next" === buttonId) {
            console.clear()
            console.log(this.props.location.state)
            this.props.history.push({
                pathname: '/OnlineAssessorApprovalQualificationScope',
                state: {
                    selection: this.props.location.state.selection
                }
            });
        } else if ("back" === buttonId) {
            this.close();
        }
    }

    handleToolbarButtonMenuClick(menu) {
    }

    close() {
        this.props.history.push('/OnlineAssessorApprovalSearch');
    }

    successCallback = () => {

        this.setState({ message: "Assessor updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
    }

    render() {
       
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Assessor Approval " + (this.state.AssessorDetails.FirstName !== null ? "> " + this.state.AssessorDetails.FirstName + " " + this.state.AssessorDetails.Surname + (this.state.AssessorDetails.IDNo !== null ? " - " + this.state.AssessorDetails.IDNo : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Assessor" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "64vh", maxHeight: "64vh" }}>
                                    <AssessorForm ref={assessorRef} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission = {this.props.menuPermissionItem.Edit} />
                                </div>
                            </td> 
                        </tr>
                    </tbody>
                </table>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleLowerToolbarButtonClick(buttonId)} config={lowertoolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AssessorApproval));
