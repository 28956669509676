import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import DocumentUpload from '../../widgets/DocumentUpload';
import ViewUtils from "../../ViewUtils";
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';

const utils = new ViewUtils();
export default function AssModDocumentsDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [filename, setFilename] = React.useState("");
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [entityId, setEntityId] = React.useState(props.EntityID);
    const [documentType, setDocumentType] = React.useState("");

    React.useEffect(() => {
        if (!utils.isNull(props.EntityID)) {
            setEntityId(props.EntityID);
        }
    }, [props.EntityID]);

    const handleClose = (save, cancel) => {
        setOpen(false);
        props.closeHandler(save, cancel)
    };


    const cancel = () => {
        handleClose(false, true);
    };

    const save = () => {
        let data = {};
        data.OriginalFileName = filename;
        data.EntityID = entityId;
        data.Document = {};
        data.Document.name = filename;
        data.Document.size = size;
        data.Document.type = type;
        data.Document.base64 = base64;
        data.DocumentRelatesID = documentType;
        utils.invokeUrl(props.postUrl, (response) => {
            handleClose(true, false);
        }, () => { handleClose(false, false); },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        );
    }

    const handleFormValueChange = (values) => {
        setDocumentType(values.DocumentTypeID);
    }



    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md" disableBackdropClick={true}
                style={{ scroll: "body" }}>
                <div style={{ padding: "32px" }}>

                    <p className="legend" >
                        New Document
                    </p>

                    <div style={{ height: "80%" }}>
                        <EntityForm useCustomSettings={false} valueChangeHandler={values => { handleFormValueChange(values)}}> 
                            {form =>
                                <form className={form.classes.container} id="comments">
                                    <div className="row w-50">
                                        <div className="col w-100">
                                            <tr>
                                                <div>
                                                <SelectItem
                                                dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=" + props.lookupList}
                                                id="DocumentTypeID"
                                                label="Document Type"
                                                className="w-100"
                                                form={form}/>
                                                </div>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div style={{ height: "80%" }}>
                                                        <DocumentUpload showViewer={false} onUploadHandler={onUploadHandler} filename={filename}

                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </div>
                                    </div>
                                </form>
                            }
                        </EntityForm>
                    </div>


                    <DialogActions>
                        <Button onClick={save} color="primary">
                            Save
                        </Button>
                        <Button onClick={cancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
