import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import BulkApprovalSearchForm from './BulkApprovalSearchForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";

const headCells = [
    { id: "LearnerIDNumber", numeric: false, disablePadding: true, label: "Learner ID Number" },
    { id: "LearnerName", numeric: false, disablePadding: true, label: "Learner Name" },
    { id: "InterventionCode", numeric: false, disablePadding: true, label: "Intervention Code" },
    { id: "InterventionDescription", numeric: false, disablePadding: true, label: "Intervention Description" },
    { id: "TrainingProviderName", numeric: false, disablePadding: true, label: "Training Provider Name" },
]

const searchParameters = [
    { Name: "ApprovalTypeID", Value: "" },
    { Name: "ProgrammeTypeID", Value: "" },
    { Name: "ProgrammeID", Value: "" },
    { Name: "ProviderID", Value: "" },
    { Name: "LearnerID", Value: "" },
    { Name: "EmployerID", Value: "" },
    { Name: "QualityAssuranceBody", Value: "" },
    { Name: "StartDate", Value: "" },
    { Name: "EndDate", Value: "" },
    { Name: "FinancialYearID", Value: "" }
]

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const searchFormRef = React.createRef();

class BulkApproval extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                ApprovalTypeID: null,
                ProgrammeTypeID: null,
                ProgrammeID: null,
                ProviderID: null,
                EmployerID: null,
                LearnerID: null,
                QualityAssuranceBodyID: null,
                StartDate: "",
                EndDate: "",
                FinancialYearID: null
            },
            selectedLearners: null,
            keyUpSwitch: false,
            learnersApproved: false,
            clearGrid: false,
            messsageAlert: null,
            messageStyle: null,
            dependency: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            id: 'generate', label: 'Approve', icon: 'ApproveIcon', tooltip: 'Approve the selected learners',disabled: utils.isNull(this.state.selectedLearners)
                        }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("generate" === buttonId) {
            this.approve();
            toolbarRef.current.setButtonDisabled("generate", true);
        }
    }

    approve = () => {
        const LearnerProgrammeIDs = this.state.selectedLearners.map(e => e.Id);
        let data = {
            LearnerProgrammeIDs: LearnerProgrammeIDs,
            ...this.state.model
        }
        

        let url = "api/sims/bulklearnerapproval/ApproveLearners";

        utils.invokeUrl(url, (response) => {
            utils.showMessage(this, response.Message, response.MessageType);
            this.successCallback(response)
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearners: selection, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("generate", selection.length === 0);
                }
            )
        } else {
            this.setState({ selectedLearners: null, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("generate", selection.length === 0);
                }
            )
        }
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            //if(!utils.isNull(values.AssessmentDate))
            //{
            //    let newAssessmentDate = values.AssessmentDate.toLocaleDateString('en-ZA');
            //    values.AssessmentDate = newAssessmentDate;
            //}
            //if(!utils.isNull(values.ModerationDate))
            //    {
            //        let newModerationDate = values.ModerationDate.toLocaleDateString('en-ZA');
            //        values.ModerationDate = newModerationDate;
            //    }
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                if (!utils.isNull(newValues.ApprovalTypeID) && !utils.isNull(this.state.model.ApprovalTypeID) && newValues.ApprovalTypeID.Id !== this.state.model.ApprovalTypeID.Id) {
                    newValues.QualityAssuranceBodyID = null;
                }
                if (!utils.isNull(newValues.ProgrammeTypeID) && !utils.isNull(this.state.model.ProgrammeTypeID) && newValues.ProgrammeTypeID.Id !== this.state.model.ProgrammeTypeID.Id) {
                    newValues.ProgrammeID = null;
                }
                this.setState({
                    model: newValues,
                    clearGrid: true,
                    selectedLearners: null,
                    Message: ""
                });
                if (!utils.isNull(newValues.ApprovalTypeID) && JSON.stringify(this.state.dependency) !== JSON.stringify(newValues.ApprovalTypeID)) {
                    const approvalTypeID = {
                        Name: "ApprovalTypeID",
                        Value: newValues.ApprovalTypeID.Id
                    }
                    if (!utils.isNull(approvalTypeID.Value)) {
                        this.setState({ dependency: approvalTypeID })
                    }
                };
                toolbarRef.current.setButtonDisabled("generate", true);
            }
        }
    }

    onSearchRequiredFieldCheck = () => {
        if (searchFormRef.current.getValue()) {
            return true;
        }
        else {
            return false;
        }
    }

    onGridCleared = () => {
        this.setState({ clearGrid: false });
    }

    successCallback = (response) => {
        this.setState({ clearGrid: true });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">



                <AlertItem
                    message={this.state.messsageAlert}
                    alertStyle={this.state.messageStyle}
                />
                <SearchView
                    headCells={headCells}
                    dataUrl="api/sims/bulklearnerapproval/getsearch"
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={searchParameters}
                    searchLabel="SEARCH"
                    mode={this.props.mode}
                    multiSelect={true}
                    onLookupValueChange={this.props.onLookupValueChange}
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelectionChange(selection) }}
                    autoFetchData={false}
                    toolbarRef={toolbarRef}
                    onSearchRequiredFieldCheck={this.onSearchRequiredFieldCheck}
                    clearGrid={this.state.clearGrid}
                    onGridCleared={this.onGridCleared}
                    title={"Bulk QA Approval"}
                    handleObject={true}
                >
                    <BulkApprovalSearchForm
                        ref={searchFormRef}
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        model={this.state.model}
                        valueChangeHandler={this.handleFormValueChange}
                        dependency={this.state.dependency}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(BulkApproval)
);