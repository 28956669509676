import React from 'react';
import { CustomTextField } from '../../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../../widgets/CustomDatePicker';
import { EntityForm } from '../../../../widgets/Forms';
import ViewUtils from '../../../../ViewUtils';
import TabPanel from '../../../../widgets/TabPanel';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import OFOSearch from '../../Controls/OFOSearch';
import { LookupField } from '../../../../widgets/LookupField';
import Table from '@material-ui/core/Table';
import { SelectItem } from '../../../../widgets/SelectItem';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

const styles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: 50,
            textAlign: 'center',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        minWidth: 200
    },
    inputForm: {
        minWidth: '200px',
    },
    table: {
        minWidth: 650,
    },
    inputCenter: {
        textAlign: 'center'
    },
    paper: {
        position: "absolute",
        width: 600,
        height: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0.1)
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
        border: '1px solid white',
        height: 6
    },
    body: {
        fontSize: 11,
    },

}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            // width: 50,
            textAlign: 'center'
        },
    },
    body: {
        fontSize: 14,
        fontWeight: 500
    }

}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const useStyles = theme => ({
    table: {
        minWidth: 700,
    },
});

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 5;
    const left = 5;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

function GetIDFromValue(value) {
    if (value !== undefined && value !== null) {
        return value.Id
    }
    else {
        return "0"
    }
}

export const EmploymentProfileSmallForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm
            ref={ref}
            viewId={null}
            values={props.data}
            entityLookupController={props.entityLookupController}
            org="saseta"
            entityLookupId={props.id}
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="EmploymentProfileSmall">
                    <div className="row w-100">
                        <div className="col w-100">
                            <TabPanel value={tabValue} index={0} className=''>
                                <table cellPadding="2" width="80%">
                                    <tbody>
                                        <tr>
                                            <td width="50%">
                                                <CustomTextField
                                                    id="IdentityNumber"
                                                    key={tabValue}
                                                    parentId={0}
                                                    label="Identity Number"
                                                    className="w-100"
                                                    required
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                />
                                            </td>
                                            <td width="50%">
                                                <CustomTextField
                                                    id="JobTitle"
                                                    key={tabValue}
                                                    parentId={0}
                                                    label="Job Title"
                                                    className="w-100"
                                                    required
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%">
                                                <LookupField
                                                    id="OFOOccupationID"
                                                    required
                                                    label="OFO Occupation"
                                                    className="w-100"
                                                    form={form}
                                                >
                                                    <OFOSearch showTitle={true} mode='lookup' dataUrl="api/sims/WSPATRForms2025/GetWSPATRFormOFOCodeSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("OFOOccupationID", value)
                                                        }} autoFetchData={false} selection={props.selection}
                                                        WSPATRID={props.WSPATRID} />
                                                </LookupField>
                                            </td>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=Equity"
                                                    id="EquityID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Equity"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=Gender"
                                                    id="GenderID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Gender"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=Disability"
                                                    id="DisabilityID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Disability"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=Nationality"
                                                    id="NationalityID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Nationality"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                            <td width="50%">
                                                <CustomDatePicker
                                                    id="DateofBirth"
                                                    parentId={0}
                                                    key={tabValue}
                                                    label="Date of Birth"
                                                    className='{classes.textField} w-100'
                                                    required

                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl={'api/sims/WSPATRForms2025/GetHighestEducationLevelByWSPATRID?parameter=[{"Name":"WSPATRID","Value":' + props.WSPATRID + '}]'}
                                                    id="HighestEducationLevelID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Highest Education Level"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=WSPATRInstitution"
                                                    id="WSPATRInstitutionID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Institution"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=WSPATRFieldOfStudy"
                                                    id="WSPATRFieldOfStudyID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Field Of Study"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=WSPATREmploymentStatus"
                                                    id="WSPATREmploymentStatusID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Employment Status"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=Province"
                                                    id="ProvinceID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Province"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=Municipality"
                                                    id="MunicipalityID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Municipality"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%">
                                                <SelectItem
                                                    dataUrl="api/sims/WSPATRForms2025/GetLookupList?listId=WSPATRSalaryBand"
                                                    id="WSPATRSalaryBandID"
                                                    parentId={0}
                                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                    key={tabValue}
                                                    label="Salary Band"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                    required
                                                />
                                            </td>
                                            <td width="50%">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})