import React from 'react';
import { EntityForm } from '../../widgets/SearchForm';
import { CustomTextField } from '../../widgets/CustomTextField';
import { SelectItem } from '../../widgets/SelectItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

export default function WSPATRVerificationSearchForm(props) {
    const classes = useStyles();
    return (
        <EntityForm ref={React.createRef()} viewId="WSPATRVerification" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="WSPATRVerificationSearchForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <SelectItem
                                dataUrl="api/sims/WSPATRVerification/GetLookupListOrder?listid=FinancialYear&sortOrder=2"
                                id="FinancialYearID"
                                label="Financial Year"
                                className={classes.MuiSelectSelectMenu}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="SDLNumber"
                                label="SDL Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                    </div>
                </form>
            }
        </EntityForm >
    );
}