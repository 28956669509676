import React, { Component } from "react";
import "../View.css";
import "../Grid.css";
import "../Form.css";
import "../App.css";
import EnhancedTable from './ResponsiveTable';
import EnhancedToolbar from './Toolbar';
import WithEvent from './WithEvent';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import ViewUtils from '../ViewUtils';
import Prompt from './Prompt';
import AlertItem from "./AlertItem";
import Button from '@material-ui/core/Button';

export default function SearchView(props) {
    const utils = new ViewUtils();
    const [model, setModel] = React.useState(props.model);
    const [searchParameters, setSearchParameters] = React.useState(props.searchParameters);
    const [selection, setSelection] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [messageStyle, setMessageStyle] = React.useState("");
    const [showToolBar] = React.useState(false);
    const [toolbarRef] = React.useState(props.toolbarRef || React.createRef());
    const [promptOpen, setPromptOpen] = React.useState(false);
    const [keyUpSwitch, setKeyUpSwitch] = React.useState(utils.isNull(props.keyUpSwitch) ? false : props.keyUpSwitch);

    const closePrompt = () => {
        setPromptOpen(false)
    }

    React.useEffect(() => {
        if (props.keyUpSwitch !== keyUpSwitch) {
            search();
            setKeyUpSwitch(props.keyUpSwitch);
        }
    }, [props.keyUpSwitch]);

    React.useEffect(() => {
        if (JSON.stringify(model) !== JSON.stringify(props.model)) {
            setModel(props.model);
        }

    }, [props.model]);

    React.useEffect(() => {
        if (JSON.stringify(props.searchParameters) !== JSON.stringify(searchParameters)) {
            search();
            setSearchParameters(props.searchParameters);
        }
    }, [props.SearchParameters]);

    const deleteRecord = () => {
        setPromptOpen(false);
        // TODO : support bulk delete when nulti select is enabled
        let url = `${props.deleteUrl}?id=${selection[0].Id}`;
        if (props.useDeletePost) {
            utils.invokeUrlDelete(url, (data) => {
                setSelection([]);
                setMessage(data.Message);
                setMessageStyle(data.MessageType.toLowerCase())
                toolbarRef.current.setButtonDisabled("edit", true)
                toolbarRef.current.setButtonDisabled("delete", true)
                search();
            }, null);
        } else {
            utils.invokeUrl(url, (data) => {
                setSelection([]);
                setMessage(data.Message);
                setMessageStyle(data.MessageType.toLowerCase())
                toolbarRef.current.setButtonDisabled("edit", true)
                toolbarRef.current.setButtonDisabled("delete", true)
                search();
            }, null);
        }
    }

    const handleToolbarButtonClick = (buttonId, buttonPath) => {

        if ("add" === buttonId) {
            props.history.push({ pathname: !utils.isNull(props.addViewPath) ? props.addViewPath : props.entityViewPath, state: { preSelectedProp: props.preSelectedProp, preSelectedPropB: props.preSelectedPropB } });
            // props.history.push(!utils.isNull(props.addViewPath) ? props.addViewPath : props.entityViewPath);
        } else if ("close" === buttonId) {
            props.history.push({ pathname: !utils.isNull(props.addViewPath) ? props.addViewPath : (!utils.isNull(buttonPath) ? buttonPath : props.entityViewPath), state: { preSelectedProp: props.preSelectedProp, preSelectedPropB: props.preSelectedPropB } });
        } else if ("delete" === buttonId) {
            setPromptOpen(true);
        } else {

            if (selection[0] !== undefined) {
                if (buttonPath !== undefined && buttonPath !== null && buttonPath !== false) {
                    props.history.push({
                        pathname: buttonPath,
                        state: { selection: selection[0].Id, preSelectedProp: props.preSelectedProp, preSelectedPropB: props.preSelectedPropB, object: selection[0] }
                    });
                } else {
                    if (buttonPath !== false) {
                        props.history.push({
                            pathname: props.entityViewPath,
                            state: { selection: selection[0].Id, preSelectedProp: props.preSelectedProp, preSelectedPropB: props.preSelectedPropB }
                        });
                    }

                }
            }



        }
        if (!utils.isNull(props.buttonClickHandler)) {
            props.buttonClickHandler(buttonId)
        }
    }

    const handleDataArrived = (data) => {
        if (!utils.isNull(data) && props.mode === 'lookup') {
            if (data.Data.length === 0) {
                if (!utils.isNull(props.customMessage)) {
                    setMessage(props.customMessage);
                    setMessageStyle("message");
                }
                else {
                    setMessage("No data records found");
                    setMessageStyle("message");
                }
            } else {
                setMessage("");
                setMessageStyle("");
            }
        }
    }

    const isModelEmpty = () => {
        const properties = Object.getOwnPropertyNames(model);
        if (utils.isNull(properties) || properties.length === 0) {
            return true;
        }

        for (let i = 0; i < properties.length; i++) {
            if (!utils.isStringEmpty(properties[properties[i]])) {
                return false;
            }
        }

        return true;
    }

    const search = () => {
        let searchParameters = [];
        const properties = Object.getOwnPropertyNames(model);
        if (!utils.isNull(properties) && properties.length > 0) {
            for (let i = 0; i < properties.length; i++) {
                let searchParameter = {};
                searchParameter.Name = properties[i];
                if (!utils.isNull(props.handleObject)) {
                    if (searchParameter.Name.slice(searchParameter.Name.length - 2) == "ID" && model[properties[i]] !== null) {
                        searchParameter.Value = model[properties[i]].Id
                    }
                    else if (searchParameter.Name.slice(searchParameter.Name.length - 4) == "Date" && model[properties[i]] !== null) {
                        searchParameter.Value = model[properties[i]].toLocaleDateString('en-ZA');
                    }
                    else {
                        searchParameter.Value = model[properties[i]];
                    }
                }
                else {
                    searchParameter.Value = model[properties[i]];
                }

                searchParameters.push(searchParameter);
            }
        }
        setSearchParameters(searchParameters);
    }

    const handleSelectionChange = (selection) => {

        setSelection(selection);
        if (selection !== null && selection.length !== 0) {
            setMessage("")
        }
        if (props.setSelection) {
            props.setSelection(selection)
        }

        if (!utils.isNull(props.onLookupValueChange)) {
            if (utils.isNull(props.selectionCallbackMode) || props.selectionCallbackMode === "reference") {
                let objectReferences = [];
                for (let i = 0; i < selection.length; i++) {
                    let objectReference = {};
                    objectReference.Id = selection[i].Id;
                    if (selection[i][props.SecondDescriptionProp] !== undefined && selection[i][props.SecondDescriptionProp] !== null) {
                        objectReference.Description = selection[i][props.descriptionProp] + ' ' + selection[i][props.SecondDescriptionProp];
                    }
                    else {
                        objectReference.Description = selection[i][props.descriptionProp];
                    }
                    objectReference.SecondDescription = selection[i][props.SecondDescriptionProp];
                    if (selection[i][props.SecondDescriptionProp] !== undefined && selection[i][props.SecondDescriptionProp] !== null) {
                        objectReference.toString = () => { return selection[i][props.descriptionProp] + ' ' + selection[i][props.SecondDescriptionProp]; };
                    }
                    else {
                        objectReference.toString = () => { return selection[i][props.descriptionProp] };
                    }

                    objectReferences.push(objectReference);
                }

                props.onLookupValueChange(props.multiSelect ? objectReferences : objectReferences[0]);
            } else {
                props.onLookupValueChange(selection);
            }
        }

        if (props.mode !== "lookup") {
            toolbarRef.current.setButtonDisabled("edit", selection === null || selection.length === 0);
            toolbarRef.current.setButtonDisabled("delete", selection === null || selection.length === 0);
            toolbarRef.current.setButtonDisabled("generate", selection === null || selection.length === 0);
            toolbarRef.current.setButtonDisabled("download", selection === null || selection.length === 0);
            toolbarRef.current.setButtonDisabled("delink", selection === null || selection.length === 0);
        }
    }

    const handleSearchCell = (rowid, columnid) => {
        if (!utils.isNull(props.handleCell)) {
            props.handleCell(rowid, columnid)
        }
    }

    return (

        <div >
            <p className="legend">
                {props.title}
            </p>

            <AlertItem message={message} alertStyle={messageStyle} />

            <table>
                <tbody>
                    {(props.searchLabel !== undefined && props.searchLabel !== null) ?
                        <tr>
                            <td>
                                {props.children}
                            </td>
                            <td>
                                {props.search == false ? null :
                                    //<IconButton
                                    //    onClick={() => search()}
                                    //    aria-label="search">
                                    //    {props.searchLabel}
                                    //    <SearchIcon />
                                    //</IconButton>
                                    <Button
                                        variant="outlined"
                                        color="#01579B"
                                        style={{ height: '40px', marginTop: '5px' }}
                                        onClick={() => search()}>
                                        {props.searchLabel}
                                    </Button>
                                }
                            </td>
                            <td>
                                {props.allowClear !== true ? null :
                                    <IconButton
                                        onClick={() => props.clearPress()}
                                        aria-label="clear">
                                        <DeleteSweepIcon />
                                    </IconButton>
                                }
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>
                                {props.children}
                            </td>
                            <td>
                                {props.search == false ? null :
                                    <IconButton
                                        onClick={() => search()}
                                        aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                }
                            </td>
                            <td>
                                {props.allowClear !== true ? null :
                                    <IconButton
                                        onClick={() => props.clearPress()}
                                        aria-label="clear">
                                        <DeleteSweepIcon />
                                    </IconButton>
                                }
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <div>
                {
                    props.mode === "lookup" && !showToolBar ?
                        "" :
                        <EnhancedToolbar highlight={false} buttonClickHandler={(buttonId, buttonPath) => handleToolbarButtonClick(buttonId, buttonPath)}
                            mode={props.mode}
                            config={props.toolbarConfig} ref={toolbarRef} />
                }
                <EnhancedTable headCells={props.headCells} autoHeader={props.autoHeader}
                    onSelectionChange={(selection) => { handleSelectionChange(selection) }}
                    dataUrl={props.dataUrl} onDataArrive={(data) => handleDataArrived(data)}
                    mode={props.mode} autoFetchData={props.autoFetchData}
                    height={props.mode === "lookup" && !showToolBar ? "50vh" : "60vh"}
                    bodyMaxHeight={props.mode === "lookup" && !showToolBar ? "65vh" : "60vh"}
                    searchParameters={searchParameters} multiSelect={props.multiSelect} onSearchRequiredFieldCheck={props.onSearchRequiredFieldCheck}
                    clearGrid={props.clearGrid} onGridCleared={props.onGridCleared}
                    onCellSelectionChange={(rowid, columnid) => { handleSearchCell(rowid, columnid) }}
                    checkRefreshToken={props.checkRefreshToken}
                />
                <Prompt open={promptOpen}
                    promptTitle={props.promptTitle}
                    promptContent={props.promptContent}
                    handleYes={deleteRecord}
                    closePrompt={closePrompt}
                />
            </div>
        </div>
    );
}





