import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel from '../../widgets/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DocumentUpload from "../../widgets/DocumentUpload";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const OrganisationBankingDetailsForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [Bankfilename, setBankFilename] = React.useState("");
    const [Regfilename, setRegFilename] = React.useState("");
    const [Refresh, setRefresh] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function ConfirmDetailsDisableCheck(value) {
        if (value !== undefined && value !== null) {
            if (value === true) {
                return true
            }
            else if (value !== true) {
                return false
            }
        }
    };

    function DocRequiredCheck (value){
        if (value !== undefined && value !== null && value !== "") {
            return false
        }
        else if (value === undefined || value === null || value === "") {
            return true
        }
    };

    function FileDownload(id, DocumentType) {
        utils.invokeUrl("api/sims/organisationbankdetail/Download" + DocumentType + "Document?id=" + id, (response) => {

            var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    function base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const onBankUploadHandler = (data) => {
        if (data) {
            setBankFilename(data.name);
        }
    };

    const onRegUploadHandler = (data) => {
        if (data) {
            setRegFilename(data.name);
        }
    };

    const dataArrivedHandler = (values) => {
        
        if (!utils.isNull(props.dataArrivedHandler)) {
            props.dataArrivedHandler(values);
        }

        if (!DocRequiredCheck(values.BankDetailsDocumentName)) {
            ref.current.setValue("BankDetailDocument", { Base64 : ""});
        }
    }

    return (
        <EntityForm
            dataURL='api/sims/organisationbankdetail/GetFind'
            ref={ref}
            viewId="organisation"
            values={props.data}
            entityLookupController={props.entityLookupController}
            org="saseta"
            entityLookupId={props.id}
            useCustomSettings={false}
            dataArrivedHandler={dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="organisationBankDetails">
                    <div className="row w-100">
                        <div className="col w-100">
                            <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                    <Tab label="Banking Details" {...a11yProps(0)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={tabValue} index={0} className='BankDetailsPanel'>
                                <table cellPadding="2" className="w-100">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <DocumentUpload
                                                    showViewer={false}
                                                    onUploadHandler={onBankUploadHandler}
                                                    buttonLabel="Upload Bank Details"
                                                    filename={Bankfilename}
                                                    form={form}
                                                    /*required={DocRequiredCheck(form.values["BankDetailsDocumentName"])}*/
                                                    id="BankDetailDocument"
                                                />
                                            </td>
                                            <td>
                                                <DocumentUpload
                                                    showViewer={false}
                                                    onUploadHandler={onRegUploadHandler}
                                                    buttonLabel="Upload CIPC Registration Document"
                                                    filename={Regfilename}
                                                    form={form}
                                                    id="RegistrationCertificateDocument"
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            {form.values["BankDetailsDocumentName"] !== "" && !utils.isNull(form.values["BankDetailsDocumentName"]) ?
                                                <td>
                                                    <a id="aBankDetail" onClick={() => FileDownload(props.id, "BankDetails")} >Download Bank Details</a>
                                                </td>
                                                :
                                                <td>
                                                    &nbsp;
                                            </td>
                                            }

                                            {form.values["BankDetailsRegCertDocumentName"] !== "" && !utils.isNull(form.values["BankDetailsRegCertDocumentName"]) ?
                                                <td>
                                                    <a id="aRegCert" onClick={() => FileDownload(props.id, "RegistrationCertificate")} >Download CIPC Registration Document</a>
                                                </td>
                                                :
                                                <td>
                                                    &nbsp;
                                            </td>
                                            }
                                        </tr>

                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="AccountHolder"
                                                    key={tabValue}
                                                    label="Account Holder"
                                                    className="w-100"
                                                    required

                                                    form={form}
                                                />

                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/organisationbankdetail/GetLookupList?listId=BankName"
                                                    id="BankNameID"
                                                    key={tabValue}
                                                    label="Bank Name"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/organisationbankdetail/GetLookupList?listId=AccountType"
                                                    id="AccountTypeID"
                                                    key={tabValue}
                                                    required
                                                    label="Account Type"
                                                    className="w-100"
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="AccountNumber"
                                                    key={tabValue}
                                                    label="Account Number"
                                                    className="w-100"
                                                    required

                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="BranchName"
                                                    key={tabValue}
                                                    label="Branch Name"
                                                    className="w-100"
                                                    required

                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="BranchCode"
                                                    key={tabValue}
                                                    label="Branch Code"
                                                    className="w-100"
                                                    required

                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/organisationbankdetail/GetLookupList?listId=YesNo"
                                                    id="AdminDetailsCorrectID"
                                                    key={tabValue}
                                                    label="The Administrative Details Given Above Are Correct"
                                                    className="w-100"
                                                    form={form}
                                                    required

                                                />
                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/organisationbankdetail/GetLookupList?listId=YesNo"
                                                    id="BankDetailsCorrectID"
                                                    key={tabValue}
                                                    label="The Banking Details Given Above Are Correct"
                                                    className="w-100"
                                                    form={form}
                                                    required

                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/organisationbankdetail/GetLookupList?listId=YesNo"
                                                    id="BankDetailsChangedID"
                                                    key={tabValue}
                                                    label="The Banking Details Given Above Have Changed"
                                                    className="w-100"
                                                    form={form}
                                                    required

                                                />
                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/organisationbankdetail/GetLookupList?listId=YesNo"
                                                    id="NewRegCompanyID"
                                                    key={tabValue}
                                                    label="Newly Registered Company?"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="CapturedBy"
                                                    key={tabValue}
                                                    label="Captured By"
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: true,
                                                        disabled: true
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="CapturedDate"
                                                    key={tabValue}
                                                    label="Captured On"
                                                    className='{classes.textField} w-100'
                                                    InputProps={{
                                                        readOnly: true,
                                                        disabled: true
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomDatePicker
                                                    id="RegistrationDate"
                                                    key={tabValue}
                                                    label="Registration Date"
                                                    className='{classes.textField} w-100'
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomCheckbox
                                                    id="ConfirmDetails"
                                                    key={tabValue}
                                                    form={form}
                                                    disabled={ConfirmDetailsDisableCheck(form.values["ConfirmDetails"])}
                                                    InputProps={{
                                                        'aria-label': 'primary checkbox'

                                                    }}
                                                    label="Confirm Details"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})