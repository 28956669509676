import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import WSPATREvaluationSearchForm from './WSPATREvaluationSearchForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";

const utils = new ViewUtils();
const toolbarRef = React.createRef();

class WSPATREvaluationSearch extends Component {

    constructor(props) {
        super(props);


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View Evaluation', icon: 'ViewIcon', disabled: true },
                        { id: 'bulk', label: 'Recommend for Approval', tooltip: '', disabled: true },
                    ]
                }
            ]
        }

        this.searchParameters = 
            [
                { Name: "SDLNumber", Value: "" },
                { Name: "FinancialYearID", Value: "" },

            ]

        this.headCells = 
            [
                { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
                { id: 'CompanyName', numeric: false, disablePadding: true, label: 'Company Name' },
                { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
                { id: 'VerificationDate', numeric: false, disablePadding: true, label: 'Verification Date' },
                { id: 'VerifiedBy', numeric: false, disablePadding: true, label: 'Verified By' },
                { id: 'VerificationStatus', numeric: false, disablePadding: true, label: 'Verification Status' },
                { id: 'EvaluationStatus', numeric: false, disablePadding: true, label: 'Evaluation Status' },

            ]

        let model = 
            {
            SDLNumber: "",
            FinancialYearID: { Id: 27, Description: "01 April 2025 - 31 March 2026" }
            }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedWSPATR: null,
            selectedWSPATRs: null,
            showBulk: false
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection.length > 1) {
            this.setState({ selectedWSPATR: null, selectedWSPATRs: selection });
            toolbarRef.current.setButtonDisabled("bulk", false);
            toolbarRef.current.setButtonDisabled("view", true);
        }
        else if (selection.length === 1) {
            this.setState({ selectedWSPATR: selection[0].Id, selectedWSPATRs: null });
            toolbarRef.current.setButtonDisabled("bulk", true);
            toolbarRef.current.setButtonDisabled("view", false);
        }
        else {
            this.setState({ selectedWSPATR: null, selectedWSPATRs: null });
            toolbarRef.current.setButtonDisabled("bulk", true);
            toolbarRef.current.setButtonDisabled("view", true);
        }

    }

    handleToolbarButtonClick = (buttonId) => {

        if ("bulk" === buttonId) {
            if (this.state.selectedWSPATRs !== null)
            {
                let WSPATRList = "";
                const wspatrs = this.state.selectedWSPATRs;
                
                for (let i = 0; i < wspatrs.length; i++) {
                        if (i === 0) { WSPATRList += wspatrs[i].Id; }
                        else { WSPATRList += "," + wspatrs[i].Id; }

                }
                const url = `api/sims/WSPATREvaluation/UpdateEvaluationRecommend`;

                utils.invokeUrl(url, (response) => {
                    this.successCallback(response);
                    this.setState(
                        {
                            selectedWSPATRs: null,
                            clearGrid: true,
                            FinancialYearID: "0",
                        });
                    toolbarRef.current.setButtonDisabled("bulk", true);
                    toolbarRef.current.setButtonDisabled("view", true);
                },
                    (response) => {
                        this.error(response);
                    },
                    {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: JSON.stringify(WSPATRList)
                    }
                );
            }
        }
        else if ("view" === buttonId) {
            this.props.history.push({
                pathname: "/WSPATREvaluationMain",
                state: {
                    WSPATRID: this.state.selectedWSPATR
                }
            })
        }
    }
    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    onGridCleared = () => {
        this.setState({ clearGrid: false });
    }

    render() {
        return (

            <ViewContainer title={""}>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <br />
                <SearchView headCells={this.headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/WSPATREvaluation/getsearch"}
                    model={this.state.model}
                    title='WSPATR Evaluation'
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    mode={this.props.mode}
                    multiSelect={true}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="LegalName"
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    clearGrid={this.state.clearGrid}
                    onGridCleared={this.onGridCleared}
                    toolbarRef={toolbarRef}
                >
                    <WSPATREvaluationSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>

            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(WSPATREvaluationSearch)
);


