import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./QualificationSetup.css";
import { QualificationForm } from './QualificationSetupForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import DocumentUpload from "../../widgets/DocumentUpload";
import withMenuPermission from "../../widgets/withMenuPermission";


const qualificationRef = React.createRef();
const utils = new ViewUtils();

const toolbarRef = React.createRef();
class Qualification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qualificationSetupDetails: {
                SAQAQualificationID: "",
                SAQAQualificationTitle: ""
            },
            model: {
                OFOOccupationID: null,
                QualificationToBeReplacedID: null
            },
            initialUnitStandardType: null,
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
            Document: {
                OriginalFileName : "",
                EntityID : "",
                Document: {
                        name: "",
                        size: "",
                        type: "",
                        base64: ""
                     }
            },
        };

        this.defaultModel = {
            NQFLevelID: { Id: 1, Description: "Level 01" }
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'unitStandards', label: '', icon: 'LinkIcon', tooltip: 'Link unit standards to quilification',disabled: utils.isNull(this.state.id)},
                        { id: "learnership", label: '', icon: 'CreateIcon', tooltip: 'Create learnership', disabled: utils.isNull(this.state.id) },
                        { id: 'modules', label: 'Modules',  tooltip: 'Link modules to quilification', disabled: utils.isNull(this.state.id) },
                    ]
                }
            ]
        };
    }

    handleFileUpload = (data) => {
        if (!utils.isNull(data)) {
            this.setState({
                Document: {
                    OriginalFileName : data.name,
                    EntityID : data.id,
                    Document: {
                        name: data.name,
                        size: data.size,
                        base64: data.base64,
                        type: data.type
                    }
                }
            })
        }
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            if (this.state.initialUnitStandardType === null && !utils.isNull(values.QualificationTypeID)) {
                this.setState({ initialUnitStandardType: values.QualificationTypeID.Id });
                toolbarRef.current.setButtonDisabled("unitStandards", utils.isNull(this.state.id) || values.QualificationTypeID.Id != 1);
                toolbarRef.current.setButtonDisabled("modules", utils.isNull(this.state.id) || (values.QualificationTypeID.Id != 3 && values.QualificationTypeID.Id != 4));
            }
            else if (!utils.isNull(values.QualificationTypeID)) {
                if (values.QualificationTypeID.Id == 1) {
                    toolbarRef.current.setButtonDisabled("unitStandards", false);
                }
                else if (values.QualificationTypeID.Id == 3 || values.QualificationTypeID.Id == 4) {
                    toolbarRef.current.setButtonDisabled("modules", false);
                }
                else {
                    toolbarRef.current.setButtonDisabled("unitStandards", true);
                    toolbarRef.current.setButtonDisabled("modules", true);
                }
            }

            this.setState({
                qualificationSetupDetails: {
                    SAQAQualificationID: values.SAQAQualificationID,
                    SAQAQualificationTitle: values.SAQAQualificationTitle,
                    QualificationTypeID: values.QualificationTypeID
                },
                Document: { ...Document, OriginalFileName: values.OriginalFileName },
            });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("unitStandards" === buttonId) {
            this.props.history.push({
                pathname: '/qualunitstandardsearch',
                state: {
                    qualificationId: this.props.location.state.selection,
                    qualificationTitle: this.state.qualificationSetupDetails.SAQAQualificationTitle,
                    credits: this.state.qualificationSetupDetails.Credits
                }
            });
        }
        else if ("learnership" === buttonId) {
            this.props.history.push({
                pathname: '/learnershipsetupSearch',
            }) 
        } else if ("modules" === buttonId) {
            this.props.history.push({
                pathname: '/qualmodulesearch',
                state: {
                    qualificationId: this.props.location.state.selection,
                    qualificationTitle: this.state.qualificationSetupDetails.SAQAQualificationTitle,
                    credits: this.state.qualificationSetupDetails.Credits
                }
            });
        }
    }

    successCallback = (response) => {
        let qualType = response.QualificationType;
        if (!utils.isNull(qualType) && qualType !== 0) {
            toolbarRef.current.setButtonDisabled("unitStandards", qualType === 2);
        }

        if (!utils.isNull(response.EntityId) && response.EntityId !== 0) {
            this.setState({ id: response.EntityId }, () => {
                this.props.history.push({
                    pathname: "/Qualificationsetup",
                    state: { selection: this.state.id }
                })
                toolbarRef.current.setButtonDisabled("unitStandards", this.state.id === null || response.MessageType === "ERROR" || qualType === 2 || qualType === 0)
                toolbarRef.current.setButtonDisabled("learnership", this.state.id === null || response.MessageType === "ERROR")
            })
        }
    }

    save() {
        let formValue = qualificationRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let isAdd = true;
            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            formValue.Document = this.state.Document;
            const data = JSON.stringify(formValue);
            let url = "api/sims/qualificationsetupdetails/updatequalification";
            if (!isAdd) {
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            } else {
                url = "api/sims/qualificationsetupdetails/insertqualification";
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                },
                    null,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            }
        }
        //utils.saveForm(this, qualificationRef, `api/indicium/QualificationSetup/updateQualificationSetup`,this.successCallback, null);
    }

    closeView() {
        this.props.history.push('/qualificationsetupSearch');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    Qualification
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    ref={toolbarRef}
                                    title="Qualification"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                
                               
                                { !this.props.menuPermissionItem.Edit ?
                                    '' :  <DocumentUpload
                                     buttonLabel="Upload MOU"
                                     filename={this.state.Document.OriginalFileName}
                                     onUploadHandler={this.handleFileUpload}
                                     downloadButtonLabel={"Download MOU"}
                                     downloadUrl={"api/indicium/QualificationSetup/getMOUDocument?documentID=" + JSON.stringify(this.state.id)}
                                 />
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer"> 
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <QualificationForm ref={qualificationRef} data={this.state.model} id={this.state.id !== null ? this.state.id : typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit} defaultHandlerObj={this.defaultModel}
                                        viewRef={this} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(Qualification));

