import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import LearnerDocumentSearchForm from './LearnerDocumentSearchForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";

const headCells = [
    { id: "LearnerIDNumber", numeric: false, disablePadding: true, label: "Learner ID Number" },
    { id: "LearnerName", numeric: false, disablePadding: true, label: "Learner Name" },
    { id: "InterventionCode", numeric: false, disablePadding: true, label: "Intervention Code" },
    { id: "InterventionDescription", numeric: false, disablePadding: true, label: "Intervention Description" },
    { id: "TrainingProviderName", numeric: false, disablePadding: true, label: "Training Provider Name" },
]

const searchParameters = [
    { Name: "DocumentTypeID", Value: "" },
    { Name: "PrintTypeID", Value: "" },
    { Name: "ProgrammeTypeID", Value: "" },
    { Name: "ProgrammeID", Value: "" },
    { Name: "ProviderID", Value: "" },
    { Name: "LearnerID", Value: "" },
    { Name: "EmployerID", Value: "" },
    { Name: "StartDate", Value: "" },
    { Name: "EndDate", Value: "" },
    { Name: "FinancialYearID", Value: "" }
]

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const searchFormRef = React.createRef();

class LearnerDocuments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                DocumentTypeID: null,
                PrintTypeID: null,
                ProgrammeTypeID: null,
                ProgrammeID: null,
                ProviderID: null,
                EmployerID: null,
                LearnerID: null,
                StartDate: "",
                EndDate: "",
                FinancialYearID: null
            },
            selectedLearners: null,
            keyUpSwitch: false,
            clearGrid: false,
            documentGenerated: false,
            documentName: null,
            messsageAlert: null,
            messageStyle: null,
            dependency: null
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {id: 'generate', label: 'Generate', icon: 'CreateIcon', tooltip: 'Generate letters / certificates',disabled: utils.isNull(this.state.selectedLearners)}
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("generate" === buttonId) {
            this.generateDocuments();
            toolbarRef.current.setButtonDisabled("generate", true);
        }
    }

    FileDownload = (documentName) => {

        utils.invokeUrl("api/sims/bulklearnercertificate/DownloadLearnerCertificates?FileName=" + documentName, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
            toolbarRef.current.setButtonDisabled("download", true);
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );

    };

    generateDocuments = () => {
        const LearnerProgrammeIDs = this.state.selectedLearners.map(e => e.Id);
        let data = {
            LearnerProgrammeIDs: LearnerProgrammeIDs,
            ...this.state.model
        }
        let url = "api/sims/bulklearnercertificate/GenerateLearnerCertificates";
        utils.invokeUrl(url, (response) => {
            utils.showMessage(this, response.Message, response.MessageType);
            let documentInfo = JSON.parse(response.Message);
            this.FileDownload(documentInfo.OriginalFileName);
            toolbarRef.current.setButtonDisabled("download", !this.state.documentGenerated);
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearners: selection, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("generate", selection.length === 0);
                }
            )
        } else {
            this.setState({ selectedLearners: null, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("generate", selection.length === 0);
                }
            )
        }
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                if (!utils.isNull(newValues.ProgrammeTypeID) && !utils.isNull(this.state.model.ProgrammeTypeID) && newValues.ProgrammeTypeID.Id !== this.state.model.ProgrammeTypeID.Id) {
                    newValues.ProgrammeID = null;
                }
                if (!utils.isNull(newValues.DocumentTypeID) && !utils.isNull(this.state.model.DocumentTypeID) && newValues.DocumentTypeID.Id !== this.state.model.DocumentTypeID.Id) {
                    newValues.PrintTypeID = null;
                }
                this.setState({
                    model: newValues,
                    clearGrid: true,
                    selectedLearners: null,
                    Message: ""
                });
                if (!utils.isNull(newValues.DocumentTypeID) && JSON.stringify(this.state.dependency) !== JSON.stringify(newValues.DocumentTypeID)) {
                    const documentTypeID = {
                        Name: "DocumentTypeID",
                        Value: newValues.DocumentTypeID.Id
                    }
                    if (!utils.isNull(documentTypeID.Value)) {
                        this.setState({ dependency: documentTypeID })
                    }
                };
                toolbarRef.current.setButtonDisabled("generate", true);
            }
        }
    }

    onSearchRequiredFieldCheck = () => {
        if (searchFormRef.current.getValue()) {
            return true;
        }
        else {
            return false;
        }
    }

    onGridCleared = () => {
        this.setState({ clearGrid: false });
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <AlertItem
                    message={this.state.messsageAlert}
                    alertStyle={this.state.messageStyle}
                />
                <SearchView
                    headCells={headCells}
                    dataUrl="api/sims/bulklearnercertificate/getsearch"
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={searchParameters}
                    searchLabel="SEARCH"
                    mode={this.props.mode}
                    multiSelect={true}
                    onLookupValueChange={this.props.onLookupValueChange}
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelectionChange(selection) }}
                    autoFetchData={false}
                    toolbarRef={toolbarRef}
                    onSearchRequiredFieldCheck={this.onSearchRequiredFieldCheck}
                    clearGrid={this.state.clearGrid}
                    onGridCleared={this.onGridCleared}
                    title={"Learner Certificates and SOR/ROA"}
                    handleObject={true}
                >
                    <LearnerDocumentSearchForm
                        ref={searchFormRef}
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        model={this.state.model}
                        valueChangeHandler={this.handleFormValueChange}
                        dependency={this.state.dependency}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(LearnerDocuments)
);