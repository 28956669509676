import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import ProviderSearch from '../../provider/ProviderSearch';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../../widgets/Forms';
import SkillsProgramSetupSearch from '../../program/SkillsProgramSetupSearch';
import ViewUtils from "../../../ViewUtils";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnerSkillsProgramForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [completionDateCalc, setcompletionDateCalc] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    console.log(props.id);

    function GetProgrammeStatusID(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    function StatusCheck(form, status, programmeID) {
        if (!props.hideFields && programmeID !== undefined && status !== null) {

            if (status.Description === "Registered") {
                let url = "api/sims/learnerskillsprogramme/GetCompletionDate?SPID=" + programmeID.Id;
                utils.invokeUrl(url, (response) => {
                    form.values['CompletionDate'] = new Date(response);
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }

        }
        return false
    }

    //function SocioCheck(value) {
    //    if (value !== undefined && value !== null) {
    //        if (value.Description === "Employed") {
    //            return true
    //        }
    //        else if (value.Description !== "Unemployed") {
    //            return false
    //        }
    //    }
    //}

    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "BANKSETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }

    function ProgrammeTypeCheck(value) {
        if (value !== undefined && value !== null && value.Id !== 0) {
                return false
            }
            else {
                return true
            }
    }

    function GetProgrammeTypeID(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }


    return (
        <EntityForm ref={ref} values={props.data} viewId="SkillsProgrammeSetup" dataURL="api/sims/learnerskillsprogramme/GetLearnerLinkedSkillsProgrammeDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerSkillsProgramForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Skills Programme Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                    <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerskillsprogramme/GetLookupList?listId=LearnerSkillsProgrammeType"
                                                id="LearnerSkillsProgrammeTypeID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : (!utils.isNull(props.id) && props.id > 0)  ? true : false}
                                                key={tabValue}
                                                label="Skills Programme Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="ProviderID"
                                                label="Provider"
                                                className="w-100"
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <ProviderSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerskillsprogramme/ProviderSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ProviderID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="SkillsProgrammeID"
                                                label="Skills Program"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : (!utils.isNull(props.id) && props.id > 0) ? true : ProgrammeTypeCheck(form.values["LearnerSkillsProgrammeTypeID"])}
                                                form={form}
                                            >
                                                <SkillsProgramSetupSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerskillsprogramme/SkillsProgrammeSearch" LearnerSkillsProgrammeTypeID={GetProgrammeTypeID(form.values["LearnerSkillsProgrammeTypeID"])} 
                                                    onLookupValueChange={(value) => form.setLookupSelection("SkillsProgrammeID", value)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Employer"
                                                className="w-100"
                                                form={form}
                                                required={SponsorCheck(form.values["SponsorshipID"])}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerskillsprogramme/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="StudentNumber"
                                                key={tabValue}
                                                label="Student Number"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CommencementDate"
                                                key={tabValue}
                                                label="Commencement Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Completion Date"
                                                className='{classes.textField} w-100 '
                                                required={StatusCheck(form, form.values["ProgrammeStatusID"], form.values["SkillsProgrammeID"])}
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ContractNumber"
                                                key={tabValue}
                                                label="Contract Number"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="StatusEffectiveDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Status Effective Date"
                                                className='{classes.textField} w-100 '
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerskillsprogramme/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerskillsprogramme/GetLookupList?listId=Sponsorship"
                                                id="SponsorshipID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Sponsorship"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerskillsprogramme/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerskillsprogramme/GetLookupList?listId=Project"
                                                id="ProjectID"
                                                parentId={0}
                                                required={SponsorCheck(form.values["SponsorshipID"])}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Project"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                allowEditEmptyValue={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="MostRecentRegistrationDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Most Recent Registration"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="EstimateCompletionDate"
                                                key={tabValue}
                                                label="Estimate Completion Date"
                                                className='{classes.textField} w-100'
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            (!props.hideFields) ?
                                        <td>
                                            <CustomTextField
                                                id="CertificateNumber"
                                                key={tabValue}
                                                label="Certificate Number"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                                :
                                                ""
                                        }
                                        {
                                            (!props.hideFields) ?
                                        <td>
                                            <CustomDatePicker
                                                id="DateCertificateCreated"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Date Certificate Created"
                                                className='{classes.textField} w-100 '
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                                :
                                                ""
                                        }
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/learnerskillsprogramme/GetProgrammeStatusByRole?parameter=[{"Name":"ProgrammeStatusId","Value":' + GetProgrammeStatusID(form.values["ProgrammeStatusID"]) + '}]'}
                                                id="ProgrammeStatusID"
                                                parentId={0}
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                label="Programme Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                                </td>
                                              
                                        <td>
                                            <CustomDatePicker
                                                id="ExtensionDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                        label="Extension Date"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                      :
                                                ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionCapturedDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Captured Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionReason"
                                                        key={tabValue}
                                                        label="Extension Reason"
                                                        className="w-100 "
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                      
                                              
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerskillsprogramme/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerskillsprogramme/GetLookupList?listId=EnrolmentStatusReason"
                                                        id="EnrolmentStatusReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Enrolment Status Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                     
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                    <td>
                                                        <CustomDatePicker
                                                            id="RegistrationDate"
                                                            key={tabValue}
                                                        label="Registration Date"
                                                            className='{classes.textField} w-100 '
                                                            disabled
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            form={form}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomTextField
                                                            id="RegisteredBy"
                                                            key={tabValue}
                                                        label="Registered By"
                                                            className="w-100 "
                                                            disabled
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        />
                                                    </td>
                                                </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="RegistrationNumber"
                                                        key={tabValue}
                                                        disabled
                                                        label="Registration Number"
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="SkillsProgrammeReferenceNumber"
                                                        key={tabValue}
                                                        label="Skills Programme Reference Number"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
